import React from "react";
import AnnaDaan from "./assets/anna daan.png";
import Flower from "./assets/flower.png";
import Vaikunta from "./assets/Vaikunta.png";
import GauSeva from "./assets/gauseva.png";

import { useNavigate } from "react-router-dom";

const SevasPage = () => {
  const navigate = useNavigate();
  const PaymentLinks = {
    annaDaan: "https://rzp.io/rzp/X3L0i52",
    flowerDecoration: "https://rzp.io/rzp/UWsHcBj",
    vaikuntaDwaram: "https://rzp.io/rzp/CA6hUtZN",
    gauSeva: "https://rzp.io/rzp/S0x8p7B",
  };
  const sevas = [
    {
      id: "annaDaan",
      title: "Anna daan Seva",
      description: `"Anna daan is the greatest donation." Your contributions will help us distribute sanctified prasadam (food) to thousands of devotees and the underprivileged.`,
      image: AnnaDaan,
      alt: "People serving food",
    },
    {
      id: "flowerDecoration",
      title: "Flower Decoration Seva",
      description:
        "Adorn the temple and deities with vibrant floral decorations, enhancing the divine atmosphere for all who visit",
      image: Flower,
      alt: "Flower decorations",
    },
    {
      id: "vaikuntaDwaram",
      title: "Vaikunta Dwaram Seva",
      description:
        "Support the decoration of the celestial gateway, symbolizing the entry to Vaikunta",
      image: Vaikunta,
      alt: "Temple gateway",
    },
    {
      id: "gauSeva",
      title: "Gau Seva",
      description:
        "Serve the sacred cows, revered as mothers in Vedic tradition. Your support ensures their care and well-being.",
      image: GauSeva,
      alt: "Person feeding a cow",
    },
  ];

  const handleDonate = (sevaId) => {
    window.location.href = PaymentLinks[sevaId];
  };

  return (
    <div className=" p-4 sm:p-6 md:p-8 lg:p-12 xl:p-16 2xl:p-20 space-y-0">
      <h1 className="font-semibold text-2xl sm:text-3xl md:text-4xl lg:text-5xl 2xl:text-7xl text-center text-red-100 mb-8 lg:mb-12  text-transparent bg-clip-text bg-gradient-to-b from-[#C61D21] to-[#600E10]">
        Sevas You Can Support
      </h1>
      <div className="max-w-3xl bg-gradient-to-b from-[#C61D21] to-[#600E10] md:max-w-4xl lg:max-w-6xl xl:max-w-7xl 2xl:max-w-[98%] mx-auto rounded-3xl shadow-2xl p-6 md:p-8 lg:p-10">
        <div className="space-y-6 md:space-y-8">
          {sevas.map((seva, index) => (
            <div
              key={index}
              className="flex flex-col sm:flex-row gap-4 md:gap-6 lg:gap-8"
            >
              {/* Image Container */}
              <div className="w-full sm:w-1/4 lg:w-1/4 2xl:w-[39%] flex justify-center sm:block">
                <div className="rounded-[2rem] overflow-hidden p-2 bg-gradient-to-b from-[#C61D21] to-[#DE7D0D] w-full">
                  <img
                    src={seva.image}
                    alt={seva.alt}
                    className="w-full h-48 sm:h-40 lg:h-48 2xl:h-80 object-cover rounded-[1.5rem]"
                  />
                </div>
              </div>

              {/* Content Container */}
              <div className="flex-1 flex flex-col items-center sm:items-start">
                <h2 className="text-2xl sm:text-2xl xl:text-3xl 2xl:text-5xl font-bold text-yellow-500">
                  {seva.title}
                </h2>
                <p className="text-xl mb-1 sm:mb-[4rem] sm:text-base xl:text-2xl 2xl:text-4xl text-white text-left">
                  {seva.description}
                </p>
                <button
                  className="mt-2 px-14 py-3  font-bold bg-[#BC3521] hover:bg-red-900 text-white rounded-lg transition-colors duration-200 text-sm sm:text-base 2xl:text-4xl border"
                  // onClick={() => navigate(`/DonationForm/${seva.id}`)}
                  onClick={() => handleDonate(seva.id)}
                >
                  DONATE NOW
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SevasPage;
