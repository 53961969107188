import React from "react";
import "./heading.css";

export const Heading = () => {
  return (
    <>
      <div className="heading-upper">
        <div className="name">
          <div className="BHAKTI-SASHTRI">BHAKTI VRIKSHA</div>
        </div>
      </div>
      <div className="label">
        <p className="bhakti-shastri">
          <span className="text-wrapper full-text">
            Bhakti-Vriksha at ISKCON Vijayawada:
            <br />{" "}
          </span>
          <span className="text-wrapper mobile-text">
            Bhakti-Vriksha at ISKCON Vijayawada:
            <br />{" "}
          </span>
          <span className="span"> Growing in Devotion</span>
        </p>
      </div>
    </>
  );
};
