import React, { useEffect } from "react";
import "./styles.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import langerImg from "./assets/langer.png";
import krishnaImg from "./assets/krishna.png";
import riverImg from "./assets/river.png";
import poojaImg from "./assets/pooja.png";
import { useNavigate } from "react-router-dom";

function DonationTemp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const donateCardData = [
    {
      id: 1,
      title: "Annadaan",
      description:
        "Your open-hearted contribution may make a difference in lives of millions of needy people.",
      link: "/food-for-life",
      image: langerImg,
    },
    {
      id: 2,
      title: "Maha Kumbh Mela",
      description:
        "MAHA KUMBH MELA 2025. Please support ISKCON to serve millions of pilgrims/sadhus in MAHA KUMBH MELA.",
      link: "/MahaKumbhMela",
      image: riverImg,
    },
    {
      id: 3,
      title: "Nitya Seva",
      description:
        "Nitya Seva is a monthly donation program where a devotee like you can monthly contribute in the lotus feet of the Lord.",
      link: "/nitya-seva",
      image: poojaImg,
    },
  ];
  return (
    <>
      <Header />
      <div className="donate-container w-full mb-4 ">
        <div>
          <img className="relative" src={krishnaImg} alt="Krishna Img" />
        </div>
        <div className="text-center p-4">
          <p className="text-xl text-black lg:text-5xl font-serif font-bold">
            Turn Your Money Into Prasad
          </p>
          <p className="lg:text-xl lg:p-6 text-black">
            If you would like to make a donation towards a particular area of
            activity, please select an option from below. ISKCON relies entirely
            on voluntary donations and so every donation counts. Please note
            that donation is processed on a secure site.
          </p>
        </div>
        <div className="w-full space-y-4 lg:space-y-0 lg:gap-6 flex flex-col lg:flex-row items-center justify-center">
          {donateCardData.map((donate) => (
            <div
              className="w-[98%] lg:w-[30%] bg-[#C61D21] flex flex-col justify-center items-center p-2 rounded-xl space-y-4"
              key={donate.id}
            >
              <img src={donate.image} alt={donate.title} />
              <p className="text-[#FFBA21] text-xl lg:text-2xl font-bold">
                {donate.title}
              </p>
              <p className="text-white text-sm lg:text-base lg:w-[90%]">
                {donate.description}
              </p>
              <button
                onClick={() => {
                  navigate(donate.link);
                }}
                className="text-white bg-[#F3B01B] mt-3 mb-3 p-2 text-xl font-bold border-solid border-2 border-white rounded-xl"
              >
                Donate Now
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DonationTemp;
