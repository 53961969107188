import React from "react";
import Page1 from "./page1/page1";
import Page2 from "./page2/page2";
import Page3 from "./page3/page3";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const BhaktiShashtri = () => {
  return (
    <>
      <Header />
      <Page1 />
      <Page2 />
      <Page3 />
      <Footer />
    </>
  );
};

export default BhaktiShashtri;
