import React from "react";
import guru from "../../img/16807694_1378471962242406_8392921627802468018_n1 1.png";
import "./mainSection.css";

const MainSection = () => {
  return (
    <div className="main-section-wrapper">
      <div className="main-section-container">
        <div className="main-section">
          <div className="text-section">
            The <span className="name-color">Bhakti-Vriksha</span> program at
            ISKCON Vijayawada is inspired by Lord Caitanya Mahaprabhu's
            teachings, where devotion is likened to a tree that grows and
            branches out. Bhakti-Vriksha groups are small congregational
            gatherings, designed to nurture spiritual growth and spread the
            message of Krishna consciousness. <br />
            <br />
            Each group, comprising around 15 devotees, meets weekly to engage in
            devotional practices and plan outreach activities. As the group
            grows, it splits, ensuring personal care and maintaining a strong
            community bond. The program emphasizes systematic training,
            leadership development, and active preaching, enabling devotees to
            deepen their practice while sharing their knowledge with others.
          </div>
          <div className="image-section">
            <div className="image-container">
              <img
                src={guru}
                alt="Spiritual Leader in Traditional Dress"
                className="spiritual-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
