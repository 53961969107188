import React, { useState, useEffect } from "react";
import { ArrowUp, MessageCircleMore } from "lucide-react";

const WhatsAppScroll = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate scroll progress percentage
      const totalHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progress);

      // Show button after 100vh scroll
      const isScrolled = window.scrollY > window.innerHeight;
      setShowScrollTop(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    const duration = 1000; // Duration in milliseconds
    const start = window.scrollY;
    const startTime = performance.now();

    const easeInOutCubic = (t) => {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    };

    const animateScroll = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      window.scrollTo(0, start * (1 - easeInOutCubic(progress)));

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  const openWhatsApp = () => {
    window.open("https://wa.me/+918006880038", "_blank");
  };

  return (
    <div className="fixed bottom-6 right-6 flex flex-col gap-4 z-50">
      {/* Scroll to Top Button with Progress Ring */}
      <button
        onClick={scrollToTop}
        className={`
          group relative w-12 h-12 flex items-center justify-center
          ${
            showScrollTop
              ? "opacity-100 visible translate-y-0"
              : "opacity-0 invisible translate-y-10"
          }
          transition-all duration-500 ease-in-out
        `}
        aria-label="Scroll to top"
      >
        {/* Progress Ring */}
        <svg className="absolute w-full h-full -rotate-90">
          <circle
            className="text-gray-200"
            strokeWidth="2"
            stroke="currentColor"
            fill="transparent"
            r="20"
            cx="24"
            cy="24"
          />
          <circle
            className="text-[#c61d20] transition-all duration-300"
            strokeWidth="2"
            stroke="currentColor"
            fill="transparent"
            r="20"
            cx="24"
            cy="24"
            strokeDasharray={125.6}
            strokeDashoffset={125.6 * (1 - scrollProgress / 100)}
          />
        </svg>

        {/* Button Background */}
        <div className="absolute inset-0 bg-red rounded-full shadow-lg transform transition-transform group-hover:scale-110" />

        {/* Arrow Icon */}
        <ArrowUp className="w-6 h-6 text-white relative z-10 transform transition-transform group-hover:-translate-y-1" />
      </button>

      {/* WhatsApp Button */}
      <button
        onClick={openWhatsApp}
        className="relative w-12 h-12 group grid"
        aria-label="Contact on WhatsApp"
      >
        <div className="absolute inset-0 bg-[#25D366] rounded-full shadow-lg transform transition-transform group-hover:scale-110" />
        <MessageCircleMore className="relative z-10 w-6 h-6 text-white transform translate-x-3 translate-y-3" />
      </button>
    </div>
  );
};

export default WhatsAppScroll;
