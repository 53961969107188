import React, { useState } from "react";

import footerImg from "./assets/image/footer_img.png";
import itemImg1 from "./assets/image/daily.png";
import itemImg2 from "./assets/image/temple.png";
import itemImg3 from "./assets/image/Vaishanava.png";
import itemImg4 from "./assets/image/reach.png";
import phone from "./assets/image/phone.svg";
import whatsapp from "./assets/image/whatsapp.svg";
import mail from "./assets/image/mail.svg";
import pin from "./assets/image/pin.svg";
import footerLogo from "./assets/image/footerLogo.png";
import insta from "./assets/image/Instagram.png";
import facebook from "./assets/image/Facebook Circled.png";
import youtube from "./assets/image/YouTube.png";
import linkedin from "./assets/image/LinkedIn.png";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.css";

const SpinnerButton = ({ isLoading, children, className, ...props }) => {
  return (
    <button
      {...props}
      disabled={isLoading}
      className={`${className} relative flex items-center justify-center`}
    >
      <span className={`${isLoading ? "invisible" : "visible"}`}>
        {children}
      </span>

      {isLoading && (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="w-5 h-5 border-2 border-gray-300 border-t-current rounded-full animate-spin"></div>
        </div>
      )}
    </button>
  );
};

function Footer() {
  const navigate = useNavigate();

  // State for newsletter form inputs
  const [newsletterData, setNewsletterData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewsletterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle newsletter form submit
  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();

    if (
      newsletterData.name.trim() == "" ||
      newsletterData.email.trim() == "" ||
      newsletterData.phone.trim() == ""
    ) {
      alert("Plaese enter all fields!");
      return;
    }

    setIsLoading(true);
    const formBody = new URLSearchParams(newsletterData).toString();

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxm7bFMY0t6BqBU-n3wzexFefFh1ojo7YEq3HZEYzWd2p3eUMH0mDWk_nMi-vU3Y54/exec",
        {
          method: "POST",
          body: formBody,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      alert("Successfully subscribed to newsletter");
      setNewsletterData({
        name: "",
        email: "",
        phone: "",
      });
    } catch (error) {
      // setMessage("An error occurred while submitting the form.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section>
      <div className="row pt-4 align-items-center justify-content-between gap-3 w-[90%] mx-auto">
        <div className="col-12 col-lg-4 pt-3 d-flex justify-content-center align-items-center">
          <div className="imgContainer">
            <img className="footerImg" src={footerImg} alt="Footer" />
            <div className="textBox">
              <h3 className="imgSub">His Divine Grace</h3>
              <h4 className="imgHead">A.C. BHAKTIVEDANTA SWAMI PRABHUPADA</h4>
            </div>
          </div>
        </div>

        <div className="col-12 mob-contact">
          <h1 className="textHead">CONTACT US</h1>
          <div className="d-flex mb-2 gap-3 pl-8">
            <img src={phone} alt="phone icon" />
            <h3 style={{ color: "white", fontWeight: "400" }}>
              Call: 8006880038
            </h3>
          </div>
          <div className="d-flex mb-2 gap-3 pl-8">
            <img src={whatsapp} alt="whatsapp icon" />
            <h3 style={{ color: "white", fontWeight: "400" }}>
              Whatsapp: 9955685568
            </h3>
          </div>

          <div className="d-flex mb-2 gap-3 pl-8">
            <img src={mail} alt="e-mail icon" />
            <h3 style={{ color: "white", fontWeight: "400" }}>
              Email: seva@iskconvijayawada.org
            </h3>
          </div>
        </div>

        <div className="col-12 col-lg-4 pb-4 ">
          <div className="Box">
            <h1 className="textHead">NEWSLETTER</h1>
            <form onSubmit={handleNewsletterSubmit}>
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="Name"
                value={newsletterData.name}
                onChange={handleInputChange}
              />
              <input
                className="form-control"
                type="email"
                name="email"
                placeholder="Email"
                value={newsletterData.email}
                onChange={handleInputChange}
              />
              <input
                className="form-control"
                type="phone"
                name="phone"
                placeholder="Phone"
                value={newsletterData.phone}
                onChange={handleInputChange}
              />
              <SpinnerButton
                type="submit"
                className="btn form-btn"
                isLoading={isLoading}
              >
                Subscribe
              </SpinnerButton>
            </form>
          </div>
        </div>
        <div className="col-12 col-lg-3">
          <div className="Box">
            <h1 className="textHead">QUICK LINKS</h1>
            <div
              className="item-text cursor-pointer"
              style={{ padding: "10px 15px" }}
            >
              <img
                className="item-img"
                src={itemImg1}
                alt="Item1"
                style={{ width: "45px", height: "45px", marginInlineStart: 6 }}
              />
              <a
                href="https://chat.whatsapp.com/LLGNidlvgoCBc0jeiwFvQ5"
                target="_blank"
                className="text no-underline"
              >
                {" "}
                Daily Darshan
              </a>
            </div>
            <div className="item-text cursor-pointer">
              <img className="item-img" src={itemImg2} alt="Item2" />
              <h3
                onClick={() => {
                  navigate("/Arti");
                  window.scrollTo(0, 0);
                }}
                className="text"
              >
                Temple Schedule
              </h3>
            </div>
            <div className="item-text cursor-pointer">
              <img className="item-img" src={itemImg4} alt="Item4" />
              <h3
                onClick={() => {
                  navigate("/HowToReach");
                  window.scrollTo(0, 0);
                }}
                className="text"
              >
                How to Reach
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row our-temple align-items-start">
        <div className="col-12 col-lg-6 pb-4 text-center">
          <h2 className="textHead">OUR TEMPLES</h2>
          <div>
            <div className="d-flex justify-content-center align-items-center">
              <img src={pin} alt="Location pin icon" />
              <h5 className="footer-text">
                ISKCON SRI RADHA SHYAM SUNDAR MANDIR
              </h5>
            </div>
            <h6 className="footer-sub-text">
              Main Temple - Amaravathi Karakatta Road, Undavalli, Vijayawada,
              522 501
            </h6>
            <div className="d-flex justify-content-center">
              <img src={pin} alt="Location pin icon" />
              <h5 className="footer-text">ISKCON SRI JAGANNATH MANDIR</h5>
            </div>
            <h6 className="footer-sub-text">
              City Temple - Skew Bridge, Krishna Lanka, Vijayawada - 520013
            </h6>
          </div>
        </div>
        <div
          className="col-12 col-lg-6 footerRight hide"
          style={{ textAlign: "center" }}
        >
          <h3 className="mb-3" style={{ color: "white" }}>
            CONTACT US
          </h3>
          <div className="d-flex justify-content-center align-items-center mb-2 gap-2">
            <img src={phone} alt="phone icon" />
            <h3 style={{ color: "white", fontWeight: "400" }}>
              Call: 8006880038
            </h3>
          </div>
          <div className="d-flex justify-content-center align-items-center mb-2 gap-3">
            <img src={whatsapp} alt="whatsapp icon" />
            <h3 style={{ color: "white", fontWeight: "400" }}>
              Whatsapp: 9955685568
            </h3>
          </div>
          <div className="d-flex justify-content-center align-items-center mb-2 gap-3">
            <img src={mail} alt="e-mail icon" />
            <h3 style={{ color: "white", fontWeight: "400" }}>
              Email: seva@iskconvijayawada.org
            </h3>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div
          className="copyright"
          style={{
            borderTop: "1px solid white",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="flex items-center footer-copyright mb-2">
            <img src={footerLogo} alt="Footer Logo" className="footer-logo" />
            <h5
              className="m-0"
              style={{
                color: "white",
                fontWeight: "400",
              }}
            >
              © 2024 ISKCON Vijayawada
            </h5>
            <a
              href="#"
              style={{ textDecoration: "none" }}
              className="flex justify-center items-center"
            >
              <Link
                to="/policies-usage"
                className="text-white no-underline hover:underline text-lg"
              >
                Policies of usage
              </Link>
            </a>
          </div>
          <div className="d-flex gap-3 justify-content-center">
            <button
              onClick={() =>
                window.open(
                  "https://www.instagram.com/iskconvijayawada?igsh=bGZ2aWo1ejd6bDRp",
                  "_blank"
                )
              }
              style={{ background: "none", border: "none", padding: 0 }}
            >
              <img src={insta} alt="Instagram" />
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://www.facebook.com/iskconvja1?mibextid=kFxxJD",
                  "_blank"
                )
              }
              style={{ background: "none", border: "none", padding: 0 }}
            >
              <img src={facebook} alt="Facebook" />
            </button>
            <button
              onClick={() =>
                window.open("https://www.youtube.com/@chakradharidas", "_blank")
              }
              style={{ background: "none", border: "none", padding: 0 }}
            >
              <img src={youtube} alt="YouTube" />
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/75569807/admin/inbox/thread/2-NzJmODJkZjYtNjUxOC00ZjE1LWFmMDYtOTliYWIzODA0NWU2XzAxMA==/",
                  "_blank"
                )
              }
              style={{ background: "none", border: "none", padding: 0 }}
            >
              <img src={linkedin} alt="LinkedIn" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
