import Building2 from "../../assets/images/🦆 emoji _school_.png";
import BookOpen from "../../assets/images/Vector (3).png";
import Users from "../../assets/images/🦆 icon _people_.png";
import Coffee from "../../assets/images/Frame 1707480774.png";
import Globe2 from "../../assets/images/Vector (4).png";
import Hospital from "../../assets/images/🦆 icon _Hospital_.png";
import Mic2 from "../../assets/images/Vector (5).png";
import Leaf from "../../assets/images/Vector (7).png";
import UtensilsCrossed from "../../assets/images/Vector (8).png";
import Building from "../../assets/images/Vector (9).png";
import Background from "../../assets/images/background.png";

export default function List() {
  return (
    <div
      className="min-h-screen bg-cover bg-center bg-no-repeat p-6 md:p-12"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundColor: "#ffe4d6",
      }}
    >
      <div className="max-w-7xl mx-auto">
        <h1 className="text-[#cc2828] text-4xl md:text-5xl font-bold text-center mb-12">
          ISKCON&apos;s Accomplishments Over Past 50 Years
        </h1>

        <div className="grid md:grid-cols-2 gap-6">
          {/* Centers & Temples */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg">
              750-The Number Of ISKCON Centers, Temples And Schools Worldwide.
            </p>
            <img src={Building2} className="w-16 h-16 flex-shrink-0" />
          </div>

          {/* Books Published */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg ">
              550 Million The Number Of Books Published And Distributed By
              ISKCON Worldwide.
            </p>
            <img
              src={BookOpen}
              className="w-16 h-16 flex-shrink-0 md:-order-1"
            />
          </div>

          {/* Worshippers Left */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg">
              15 Million The Number Of People Who Worship In ISKCON Temples
              Around The World, Every Year.
            </p>
            <img src={Users} className="w-16 h-16 flex-shrink-0" />
          </div>

          {/* Worshippers Right */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg">
              15 Million The Number Of People Who Worship In ISKCON Temples
              Around The World, Every Year.
            </p>
            <img src={Users} className="w-16 h-16 flex-shrink-0 md:-order-1" />
          </div>

          {/* Meals Distributed */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg">
              1.3 Million The Number Of Meals Distributed Every Day To Needy
              School Children In India By ISKCON Food Relief Foundation, Also
              Known As &apos;Annamrita&apos; As Part Of The Governement&apos;s
              Mid-Day Meal Programme.
            </p>
            <img src={Coffee} className="w-16 h-16 flex-shrink-0" />
          </div>

          {/* Devotees Walked */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg">
              260000-Devotees Walked 260000 Kms Visiting 52,000 Towns And
              Villages In 170 Countries As Part Of The Worldwide Padayatra To
              Bring The Holy Name To Every Town And Village Around The World.
            </p>
            <img src={Globe2} className="w-16 h-16 flex-shrink-0 md:-order-1" />
          </div>

          {/* Patients Treated */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg">
              3,40,000-The Number Of Patients Treated By ISKCON Hospitals,
              Mobile Clinics And Eye Camps.
            </p>
            <img src={Hospital} className="w-16 h-16 flex-shrink-0" />
          </div>

          {/* Initiated Disciples */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg">
              100,000-Initiated Disciples. In Bengal Alone &gt;1 Cr Chanting
            </p>
            <img src={Mic2} className="w-16 h-16 flex-shrink-0 md:-order-1" />
          </div>

          {/* Krishna Festivals */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg">
              6000-There Are More Than 6000 Hare Krishna Festivals Held Every
              Year Around The World.
            </p>
            <img src={Users} className="w-16 h-16 flex-shrink-0" />
          </div>

          {/* Eco-Friendly Farms */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg">
              65 The Number Of Eco-Friendly Farms Run By ISKCON To Practice
              &apos;simple Living And High Thinking&apos;.
            </p>
            <img src={Leaf} className="w-16 h-16 flex-shrink-0 md:-order-1" />
          </div>

          {/* Restaurants */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center gap-4 text-white">
            <p className="text-lg !mb-0">
              110-The Number Of Hare Krishna Restaurants Around The World.
            </p>
            <img src={UtensilsCrossed} className="w-16 h-16 flex-shrink-0" />
          </div>

          {/* Rath Yatra */}
          <div className="bg-[#cc2828] rounded-[30px]  md:rounded-[100px] p-6 md:p-12 flex items-center md:justify-start justify-between  gap-4 text-white">
            <p className="text-lg !mb-0">
              350-Rath Yatra Across The Globe Per Year
            </p>
            <img
              src={Building}
              className="w-16 h-16 flex-shrink-0 md:-order-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
