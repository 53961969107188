import React, { useEffect } from "react";
import "./styles.css";
import forum from "./assets/forum1.jpg";
import forum2 from "./assets/forum2.jpg";
import mobPage from "./assets/mob-page.jpg";
import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import Krishna from "./assets/krishna.png";
import background from "./assets/background.png";
import headingImg from "./assets/firstThreeCombinedDesktopBg.png";

function YouthSeminar() {
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />

      <div className="w-full relative md:mt-12">
        {/* Background Pattern Container */}
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center -z-30"
          style={{ backgroundImage: `url(${headingImg})` }}
        />

        {/* Content Container */}
        <div className="relative z-10 max-w-[100%] md:max-w-[90%] mx-auto px-4 py-2">
          {/* Seminar Badge */}
          <div className="flex justify-center mb-8">
            <div className="bg-red text-white px-8 py-3 rounded-b-xl text-2xl font-bold border-2 border-white">
              YOUTH SEMINAR
            </div>
          </div>

          {/* Main Title */}
          <div className="text-center mb-12">
            <h2 className="text-4xl md:text-6xl font-semibold text-red mb-1">
              ISKCON Vijayawada Youth Forum:
            </h2>
            <h1 className="text-4xl md:text-6xl font-bold text-red">
              Nurturing Leaders Of Tomorrow
            </h1>
          </div>

          {/* First Paragraph */}
          <div className="mb-8 text-xl md:text-2xl leading-relaxed text-gray-800">
            <p className="text-justify">
              The Bhagavad-Gita, One Of The Greatest Spiritual Texts, Is
              Centered On Lord Krishna Teaching Arjuna The Path To
              Responsibility, Success, And Spirituality. These Timeless
              Principles Resonate With Today's Youth, Who Are Facing Challenges
              In Balancing Material Life With Spiritual Fulfillment. The
              Guidance From Krishna Is Needed Now More Than Ever, As It Helps
              Nurture The Integrity Of Our Relationships—Whether With Ourselves,
              Our Loved Ones, Or God.
            </p>
          </div>

          {/* Second Paragraph */}
          <div className="text-xl md:text-2xl leading-relaxed text-gray-800">
            <p className="text-justify">
              Inspired By His Divine Grace A.C. Bhaktivedanta Swami Prabhupada,
              The Visionary Founder Acharya Of ISKCON, The ISKCON Youth Forum
              (IYF) Was Established To Fulfill His Dream Of Training The Youth
              Of India In The Science Of Krishna Consciousness. Srila Prabhupada
              Envisioned That Indian Youth, Empowered With Spiritual Wisdom,
              Would Spread The Teachings Of Lord Krishna Worldwide, Following
              The Sacred Instructions Of Lord Caitanya, The 15th-Century
              Incarnation Of Krishna.
            </p>
          </div>
        </div>
      </div>

      <div className="min-h-screen p-4 relative">
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center opacity-70 -z-30"
          style={{ backgroundImage: `url(${background})` }}
        />

        <div className="flex items-center justify-center py-4">
          <div className="max-w-[100%] md:max-w-[95%] w-full bg-gradient-to-r from-orange to-[#eeb98d] rounded-2xl overflow-hidden shadow-xl">
            <div className="grid grid-cols-1 md:grid-cols-4">
              {/* Image Section */}
              <div className="relative h-[400px] md:h-[26rem] md:col-span-1">
                <img
                  src={Krishna}
                  alt="Lord Krishna illustration"
                  className="w-full h-full object-cover"
                />
              </div>

              {/* Content Section */}
              <div className="pr-4 flex flex-col md:col-span-3 p-4">
                <h1 className="text-3xl md:text-5xl font-bold mb-6 text-[#8B2801]">
                  ISKCON Youth Forum Vijayawada
                </h1>
                <p className="text-gray-800 text-xl md:text-3xl leading-relaxed">
                  At ISKCON Vijayawada, our Youth Forum began as a humble
                  initiative to bring spiritual training to college students and
                  young professionals. Over time, IYF Vijayawada has grown into
                  a vibrant community of over 100 members, offering spiritual
                  education and Vedic training to the youth of the city. Through
                  our dynamic programs, students learn the art of balancing
                  their personal, academic, and professional lives while
                  deepening their connection with Krishna Consciousness.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Main Content Container */}
        <div className="max-w-[100%] md:max-w-[90%] mx-auto space-y-8">
          {/* Our Programs Section */}
          <div className="text-center">
            <h2 className="text-3xl md:text-5xl font-bold text-red mb-4">
              Our Programs
            </h2>
            <p className="text-gray-800 text-justify text-xl md:text-2xl">
              We Provide A Range Of Workshops And Residential Programs Near
              Major Universities And Colleges. Our Training Approach Is
              Immersive, Offering Youth The Experience Of A Modern-Day Gurukula
              That Fosters Personal Growth And Spiritual Development.
            </p>
          </div>

          {/* Weekend Programs Box */}
          <div className="border-2 border-red rounded-lg p-6 bg-[#f4dec6] shadow-md">
            <p className="font-bold text-xl md:text-2xl mb-4">
              For Students Who Cannot Reside In Our Centers, We Offer Regular
              Weekend Programs That Include:
            </p>

            <ul className="space-y-2 list-disc pl-6 mb-6 font-semibold text-xl md:text-2xl">
              <li>Personality Development Through Self-Awareness</li>
              <li>Maintaining A Positive Attitude</li>
              <li>Holiness In Work And Life</li>
              <li>
                Mind Control Techniques To Improve Focus And Concentration.
              </li>
              <li>Yoga And Practical Meditation.</li>
              <li>Public Speaking And Communication Skills.</li>
              <li>Smart Skills For Career Success.</li>
              <li>Building A Successful, Balanced Life</li>
              <li>Youth Engagement Across Premier Institutions</li>
            </ul>

            <p className="mb-4 font-bold text-xl md:text-2xl">
              We Take Pride In Engaging With Students From Leading Educational
              Institutions Such As KL University, SRM AP University, AIIMS
              Mangalagiri, VIT University, And The Siddhartha Group Of Colleges.
            </p>

            <p className="font-bold text-xl md:text-2xl">
              Our Seminars And Workshops Address Critical Topics Such As
              Leadership, Self-Development, And Spirituality, Making A Lasting
              Impact On The Participants.
            </p>
          </div>

          {/* Residential Facilities Section */}
          <div>
            <h1 className="font-bold text-4xl md:text-5xl text-center text-red-100 mb-4  text-transparent bg-clip-text bg-gradient-to-b from-[#C61D21] to-[#600E10]">
              Residential Facilities
            </h1>
            <p className="text-black text-xl md:text-2xl text-justify leading-relaxed">
              Currently, <span className="text-red">ISKCON Vijayawada</span>{" "}
              provides residential facilities for boys, offering an environment
              conducive to learning the subtleties of spiritual life. These
              hostels are managed by experienced devotees, ensuring that the
              students live in a spiritually enriching atmosphere. Weekend
              programs are open to both boys and girls at the temple premises,
              making it inclusive for all.
            </p>
          </div>

          {/* Join Us Box */}
          <div className="border-2 border-red rounded-lg p-6 bg-[#f4dec6] shadow-md">
            <h2
              className="text-3xl md:text-5xl font-semibold text-red mb-4 text-center font-['
Source Serif Pro']"
            >
              Join Us And Transform Your Life
            </h2>
            <p className="font-semibold text-black text-justify text-xl md:text-2xl leading-relaxed">
              At ISKCON Vijayawada Youth Forum, We Aim To Build Spiritual
              Leaders Of Tomorrow. Whether You're A Student Or A Young
              Professional, You Will Find A Nurturing Environment Where You Can
              Thrive, Learn, And Grow. Through Our Unique Blend Of Ancient Vedic
              Wisdom And Modern Life Skills, We Empower Today's Youth To Become
              Balanced, Responsible, And Spiritually Fulfilled Individuals.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default YouthSeminar;
