import React, { useState, useEffect } from "react";
import "./VaikuntaEkadashi.css";
import banner from "./assets/banner.png";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import thirdbg from "./assets/bg3.jpg";
import barcode1 from "./assets/barcode1.jpg";
import barcode2 from "./assets/barcode2.jpg";

import SevasPage from "./fix";

const VaikuntaEkadashi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [copiedText, setCopiedText] = useState(null);
  const copyToClipboard = async (text, id) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        setCopiedText(id);
        setTimeout(() => setCopiedText(null), 2000);
      } else {
        alert("Copy functionality is not supported in this browser.");
      }
    } catch (error) {
      console.error("Failed to copy text: ", error);
      alert("Failed to copy. Please try again.");
    }
  };

  return (
    <>
      <Header />
      <div
        className="vaikunta-container bg-cover bg-center"
        style={{ backgroundImage: `url(${thirdbg})` }}
      >
        {/* Header Section */}
        <header className="vaikunta-header relative w-full ">
          {/* Image container */}
          <div className="w-full h-[70vh] sm:h-[45rem] md:h-[60rem] lg:h-[62rem] 2xl:h-[80rem] relative">
            <img
              src={banner}
              alt="Vaikunta Ekadashi Banner"
              className="w-full h-full object-cover object-top"
            />
          </div>

          {/* Text overlay - positioned absolutely over the image */}
          <div className="absolute top-0 left-0 right-0 w-full pt-8 sm:pt-6 md:pt-20 bg-gradient-to-b from-black/30 to-transparent">
            <div className="max-w-4xl mx-auto text-center">
              <h1
                className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-black 
                        font-Black_Hans_Snas text-[#cd953e]
                        [text-shadow:_2px_2px_0_#ffffff,_-2px_-2px_0_#ffffff,_2px_-2px_0_#ffffff,_-2px_2px_0_#ffffff]
                        sm:[text-shadow:_3px_3px_0_#ffffff,_-3px_-3px_0_#ffffff,_3px_-3px_0_#ffffff,_-3px_3px_0_#ffffff]
                        lg:[text-shadow:_4px_4px_0_#ffffff,_-4px_-4px_0_#ffffff,_4px_-4px_0_#ffffff,_-4px_4px_0_#ffffff]"
              >
                Vaikunta Ekadashi
              </h1>

              <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold font-['Roboto'] text-[#cd953e] mt-2">
                Experience the gateway to spiritual bliss
              </p>
            </div>
          </div>
        </header>

        {/* Introduction Section */}
        <div
          className="vaikunta-intro bg-cover bg-center"
          style={{ backgroundImage: `url(${thirdbg})` }}
        >
          <p>
            Vaikunta Ekadashi is a divine opportunity to attain spiritual
            elevation and experience the boundless grace of Lord Vishnu, the
            preserver of the universe. Deeply rooted in Vedic tradition, this
            sacred day is celebrated during the Shukla Paksha of the
            Margashirsha month. The Padma Purana glorifies Vaikunta Ekadashi as
            a day when the celestial gates of Vaikunta, Lord Vishnu’s eternal
            abode, are opened for devotees, symbolizing the soul’s passage
            toward liberation and eternal bliss.
          </p>
          <p>
            Observing Vaikunta Ekadashi through fasting, chanting, and
            devotional service not only purifies the mind and soul but also
            liberates one from the bondage of past karma. The Bhagavad Gita
            (9.26) emphasizes the power of devotion, where Lord Krishna states:
            "Patram pushpam phalam toyam yo me bhaktya prayacchati Tad aham
            bhakty-upahritam asnamiprayatatmanah" ("If one offers Me with love
            and devotion a leaf, a flower, fruit, or water, I will accept it.")
          </p>
          <p>
            This verse highlights the importance of sincerity and devotion in
            any offering, making acts of kindness such as Annadaan (food
            donation) especially meritorious on Vaikunta Ekadashi. Passing
            through the Vaikunta Dwaram on this day is symbolic of leaving
            behind material attachments and advancing toward moksha, the
            ultimate goal of human life.
          </p>
          <p>
            ISKCON Vijayawada invites you to immerse yourself in this divine
            celebration. Join us inperforming sevas, engaging in spiritual
            discourses, and contributing to activities that uplift society.
            Participate in the Annadaan Seva and other offerings to seek the
            blessings of the Supreme Lord and to share the joy of Vaikunta
            Ekadashi with countless souls.
          </p>
        </div>

        {/* Seva Section */}
        <SevasPage />
        {/* Payment Section */}
        <div
          className=" bg-cover bg-center"
          style={{ backgroundImage: `url(${thirdbg})` }}
        >
          {/* donation Through Bank */}
          <div className="mx-4 ">
            <div className="md:mx-44 md:border-5 border-2 md:rounded-2xl rounded-lg border-[#C61D21] mt-5 justify-center items-center  overflow-hidden">
              <h2 className="md:py-4 py-3 bg-[#C61D21] text-white text-center md:text-3xl text-base">
                Donation Through Bank (NEFT/RTGS){" "}
              </h2>
              <ul className=" flex flex-col justify-center xl:pl-80 md:pl-40 pl-0 md:items-start md:space-y-5 space-y-2 leading-4 my-4 items-center ">
                <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
                  <span className="md:text-2xl text-base text-[#C61D21] ">
                    Bank Name:<span className="text-black"> HDFC Bank</span>
                  </span>{" "}
                  <button
                    class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                    onClick={() => copyToClipboard("HDFC Bank", "bankName")}
                  >
                    {copiedText === "bankName" ? "Copied!" : "Copy"}
                  </button>
                </li>
                <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
                  <span className="md:text-2xl text-base text-[#C61D21]">
                    Account Name:<span className="text-black"> ISKCON</span>
                  </span>{" "}
                  <button
                    class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5  border-1 border-[#C61D21] ml-2 rounded-full"
                    onClick={() => copyToClipboard("ISKCON", "accountName")}
                  >
                    {copiedText === "accountName" ? "Copied!" : "Copy"}
                  </button>
                </li>
                <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
                  <span className=" text-[#C61D21]">
                    Account Number:{" "}
                    <span className="text-black">50100436709020</span>
                  </span>{" "}
                  <button
                    class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                    onClick={() =>
                      copyToClipboard("50100436709020", "accountNumber")
                    }
                  >
                    {copiedText === "accountNumber" ? "Copied!" : "Copy"}
                  </button>
                </li>
                <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal ">
                  <span className=" text-[#C61D21]">
                    IFSC Code: <span className="text-black">HDFC00568</span>
                  </span>
                  <button
                    class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                    onClick={() => copyToClipboard("HDFC00568", "ifscCode")}
                  >
                    {copiedText === "ifscCode" ? "Copied!" : "Copy"}
                  </button>
                </li>
                <li className="w-full md:text-2xl text-base leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
                  <span className=" text-[#C61D21]">
                    Branch:{" "}
                    <span className="text-black">ONE TOWN VIJAYAWADA </span>
                  </span>{" "}
                  <button
                    class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                    onClick={() =>
                      copyToClipboard("ONE TOWN VIJAYAWADA", "branch")
                    }
                  >
                    {copiedText === "branch" ? "Copied!" : "Copy"}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          {/* UPI Payment */}
          <div className="mx-4 ">
            <div className="md:mx-44  md:border-5 border-2 md:rounded-2xl rounded-lg border-[#C61D21] mt-10 overflow-hidden">
              <h2 className="md:py-4 py-2 bg-[#C61D21] text-white text-center md:text-3xl text-base">
                Donate Through UPI
              </h2>

              <div className="flex md:flex-row flex-col justify-around items-center pt-3 md:pb-3 pb-4 gap-4">
                <div className="md:block flex gap-2 px-1">
                  <h2 className="md:text-2xl text-sm text-[#C61D21]">
                    UPI ID:
                  </h2>
                  <p className="md:text-xl text-sm  text-black">
                    {" "}
                    ISCKONVIJAYAWADA@IDFCBANK
                  </p>
                </div>
                <img src={barcode1} alt="" width="200px" height="200px" />
                <span className="font-bold">OR</span>
                <img src={barcode2} alt="" width="200px" height="200px" />
              </div>
            </div>

            <div className="text-left md:mx-44 mt-5 pb-10">
              <h2 className="text-3xl  text-[#C61D21] text-left font-bold">
                After Donation:
              </h2>
              <p className="md:text-2xl mt-4 text-base text-black w-full text-justify font-semibold">
                Once you have donated, please share the transaction details or
                receipt via WhatsApp at{" "}
                <span className="text-red">+91 9955685568</span>. This helps us
                acknowledge your contribution and issue a receipt for tax
                exemption under Section 80G of the Income Tax Act.
              </p>
            </div>
          </div>
        </div>

        {/* Experience section */}
        <section className="w-[95%] mx-auto flex flex-col md:hidden py-4 px-2  rounded-lg mb-[5%] ">
          <h3 className="text-white text-xs font-bold font-['Open Sans'] uppercase leading-normal">
            Experience the Celebrations at ISKCON Vijayawada
          </h3>
          <h2 className="text-[#ff7200] text-[10px] font-normal font-['Open Sans'] capitalize leading-[17px]">
            The temple will host a grand celebration, including:
          </h2>
          <ul className="list-disc list-outside pl-4 text-justify">
            <li className=" text-white text-xs font-normal font-['Open Sans'] leading-snug">
              Special Darshan and Vaikunta Dwaram Entry: Walk through the
              beautifully decorated gateway symbolizing entry to the spiritual
              world.
            </li>

            <li className=" text-white text-xs font-normal font-['Open Sans'] leading-snug">
              Kirtans and Bhajans: Soul-stirring devotional music to fill your
              heart with divine joy.
            </li>
            <li className=" text-white text-xs font-normal font-['Open Sans'] leading-snug">
              Spiritual Discourses: Learn about the glories of Vaikunta Ekadashi
              and Lord Vishnu through enlightening talks.
            </li>
            <li className=" text-white text-xs font-normal font-['Open Sans'] leading-snug">
              Annadaan Distribution: Thousands of meals will be distributed to
              devotees and the needy
            </li>
          </ul>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default VaikuntaEkadashi;
