import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const MapComponent = () => {

  return (
    <div
      className="w-full h-full py-[5%]  bg-[#f9eadb] ">
      <h1 className="text-center text-3xl md:text-5xl font-bold text-[#c61d21] mb-12 font-['Roboto']">How To Reach</h1>
      <div className="w-[90%] h-full flex flex-col md:flex-row  justify-center items-center mx-auto gap-5">
        <div className="w-full md:w-[50%] h-full">
          <h2 className="text-xl md:text-3xl font-semibold text-[#c61d21] font-['Roboto']  text-center">ISKCON VIJAYAWADA</h2>
          <p className="text-center text-base md:text-xl font-light text-[#000] font-['Roboto'] ">ISKCON SRI JAGANNATH MANDIR SKEW BRIDGE, Ramalingeswara Nagar, Vijayawada, Andhra Pradesh 520013</p>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.6484897149794!2d80.6475552!3d16.4933272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35f02dafb8eca5%3A0xd679eb1fe88591ab!2sISKCON%20VIJAYAWADA!5e0!3m2!1sen!2sin!4v1735816022992!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="w-full h-96 "></iframe>
        </div>
        <div className="w-full md:w-[50%] h-full">
        <h2 className="text-xl md:text-3xl font-semibold text-[#c61d21] font-['Roboto'] text-center ">ISKCON VIJAYAWADA, SRI SRI RADHA SYAM SUNDAR MANDIR</h2>
        <p className="text-center text-base md:text-xl font-light text-[#000] font-['Roboto']">Undavalli, Amaravati, Andhra Pradesh 522501</p>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.4890778889117!2d80.59159729999999!3d16.5013887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35f129ac9bd94b%3A0x67097c6bc308784d!2sISKCON%20VIJAYAWADA%2C%20SRI%20SRI%20RADHA%20SYAM%20SUNDAR%20MANDIR!5e0!3m2!1sen!2sin!4v1735816846781!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="w-full h-96"></iframe>
        </div>
      </div>


    </div>
  );
};

export default MapComponent;
