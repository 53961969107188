import React from "react";
import { Heading } from "./heading/heading";
import "./page1.css";
import MainSection from "./mainSection/mainSection";
import shadow from "../img/Section [elementor-section].png";
import topLeft from "../img/image 21455.png";
import topRight from "../img/image 21456.png";

const Page1 = () => {
  return (
    <>
      <div className="page-1 md:mt-10">
        <div className="background-image">
          <img
            className="top1-left-image"
            src={topLeft}
            alt="Top Left Design"
            loading="lazy"
          />
          <img
            className="top1-right-image"
            src={topRight}
            alt="Top Right Design"
            loading="lazy"
          />
        </div>
        <Heading />
        <MainSection />
        <img className="shadow-img" src={shadow} alt="" />
      </div>
    </>
  );
};

export default Page1;
