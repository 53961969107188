import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BannerImgMob from "./assets/banner-img-mob.png";
import BannerImgDesk from "./assets/banner-img-desk.png";
import plateIcon from "./assets/plates.png";
import clockIcon from "./assets/clock.png";
import costIcon from "./assets/cost.png";
import iskconIcon from "./assets/iskcon.png";
import readMoreDesk from "./assets/readMoreDesk.png";
import readMoreMob from "./assets/readMoreMob.png";
import menuImgMob from "./assets/menuImgMob.png";
import menuImgDesk from "./assets/menuImgDesk.png";
import thirdbg from "./assets/bg3.jpg";
import barcode1 from "./assets/barcode1.jpg";
import barcode2 from "./assets/barcode2.jpg";
import "./MahaKumbhMela.css";
import axios from "axios";

function MahaKumbhMela() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [copiedText, setCopiedText] = useState(null);
  const copyToClipboard = async (text, id) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        setCopiedText(id);
        setTimeout(() => setCopiedText(null), 2000);
      } else {
        alert("Copy functionality is not supported in this browser.");
      }
    } catch (error) {
      console.error("Failed to copy text: ", error);
      alert("Failed to copy. Please try again.");
    }
  };

  const [formData, setFormData] = useState({
    annaDaanSeva: false,
    gitaDaanSeva: false,
    sevaDate: "",
    honoureeName: "",
    relationship: "",
    donorName: "",
    email: "",
    mobile: "",
    dob: "",
    receive80G: false,
    receiveMahaPrasadam: false,
    selectedAnnaDaanAmount: null,
    selectedGitaDaanAmount: null,
    mahaPrasadamDetails: {
      doorNumber: "",
      houseName: "",
      streetName: "",
      area: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    },
  });

  const [errors, setErrors] = useState({});
  const [show80GDetails, setShow80GDetails] = useState(false);
  const [showMahaPrasadamDetails, setShowMahaPrasadamDetails] = useState(false);
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
      if (name === "customAnnaDaanAmount" && value) {
        updatedData.selectedAnnaDaanAmount = parseFloat(value);
      } else if (name === "customGitaDaanAmount" && value) {
        updatedData.selectedGitaDaanAmount = parseFloat(value);
      }

      return updatedData;
    });
  };

  const handle80GCheckboxChange = (e) => {
    setShow80GDetails(e.target.checked);
    handleInputChange(e);
  };

  const handleMahaPrasadamCheckboxChange = (e) => {
    setShowMahaPrasadamDetails(e.target.checked);
    handleInputChange(e);
  };

  const handleAmountSelect = (section, amount) => {
    setFormData((prevData) => {
      if (section === "annaDaan") {
        const newAmount =
          prevData.selectedAnnaDaanAmount === amount ? null : amount;
        return { ...prevData, selectedAnnaDaanAmount: newAmount };
      } else if (section === "gitaDaan") {
        // Deselect if the same amount is clicked again
        const newAmount =
          prevData.selectedGitaDaanAmount === amount ? null : amount;
        return { ...prevData, selectedGitaDaanAmount: newAmount };
      }
      return prevData;
    });
  };

  const validateForm = () => {
    return true;
  };

  // const handlePayment = async () => {
  //   const amount = 500; // Example amount in INR
  //   try {
  //     const { data: order } = await axios.post('http://localhost:5000/create-order', {
  //       amount: amount,
  //       currency: 'INR',
  //     });

  //     const options = {
  //       key: 'rzp_test_XaulJtcssWPB3L', // Replace with your Razorpay key
  //       amount: order.amount,
  //       currency: order.currency,
  //       name: 'ISKCON Donation',
  //       description: 'Thank you for your support!',
  //       order_id: order.id,
  //       handler: function (response) {
  //         alert(`Payment successful! Payment ID: ${response.razorpay_payment_id}`);
  //       },
  //       prefill: {
  //         name: 'Your Name',
  //         email: 'your-email@example.com',
  //         contact: '9999999999',
  //       },
  //       theme: {
  //         color: '#F37254',
  //       },
  //     };

  //     const razorpay = new Razorpay(options);
  //     razorpay.open();
  //   } catch (error) {
  //     console.error('Payment failed', error);
  //   }
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!validateForm()) return;

  //   const totalAmount = getTotalAmount() * 100; // Amount in paise

  //   let sevaDescription = "Donation Payment"; // Default description

  //   // Check if Anna Daan Seva is selected
  //   if (formData.annaDaanSeva && formData.gitaDaanSeva) {
  //     sevaDescription = "Anna Daan Seva & Gita Daan Seva"; // Both Anna Daan and Gita Daan selected
  //   } else if (formData.annaDaanSeva) {
  //     sevaDescription = "Anna Daan Seva"; // Only Anna Daan selected
  //   } else if (formData.gitaDaanSeva) {
  //     sevaDescription = "Gita Daan Seva"; // Only Gita Daan selected
  //   }

  //   const options = {
  //     //rzp_live_SWdP39USYcVFFz
  //     key: "rzp_test_XaulJtcssWPB3L",
  //     amount: totalAmount,
  //     currency: "INR",
  //     name: "ISKCON Maha Kumbh Mela",
  //     description: sevaDescription,
  //     image: "your_logo_url",
  //     handler: function (response) {
  //       alert("Payment Successful!");
  //       storeFormData();
  //     },
  //     prefill: {
  //       name: formData.donorName,
  //       email: formData.email,
  //       contact: formData.mobile,
  //     },
  //     theme: {
  //       color: "#C61D21",
  //     },
  //     modal: {
  //       ondismiss: function () {
  //         console.log("Payment modal closed");
  //       },
  //     },
  //     notes: {
  //       donorName: formData.donorName,
  //       donorPhoneNumber: formData.mobile,
  //       donorEmail: formData.email,
  //       sevaDate: formData.sevaDate,
  //       honoureeName: formData.honoureeName,
  //       relationship: formData.relationship,
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();

  //   paymentObject.on("payment.failed", function (response) {
  //     alert("Payment Failed! " + response.error.description);
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const totalAmount = getTotalAmount();
    let isFormDataStored = false; // Flag to prevent duplicate storage

    try {
      // Create order on backend
      const response = await axios.post(
        "http://localhost:5000/api/payment/create-order",
        {
          amount: totalAmount,
          currency: "INR",
        }
      );

      const order = response.data;
      let sevaDescription = "Donation Payment";

      // Determine the seva description
      if (formData.annaDaanSeva && formData.gitaDaanSeva) {
        sevaDescription = "Anna Daan Seva & Gita Daan Seva";
      } else if (formData.annaDaanSeva) {
        sevaDescription = "Anna Daan Seva";
      } else if (formData.gitaDaanSeva) {
        sevaDescription = "Gita Daan Seva";
      }

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: totalAmount * 100,
        currency: "INR",
        name: "ISKCON Maha Kumbh Mela",
        description: sevaDescription,
        order_id: order.id,
        notes: {
          donorName: formData.donorName,
          donorPhoneNumber: formData.mobile,
          donorEmail: formData.email,
          sevaDate: formData.sevaDate,
          honoureeName: formData.honoureeName,
          relationship: formData.relationship,
        },
        handler: async function (response) {
          try {
            // Verify payment
            const verificationResponse = await axios.post(
              "http://localhost:5000/api/payment/verify-payment",
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                donationDetails: { ...formData, amount: totalAmount },
              }
            );

            if (verificationResponse.data.success) {
              alert("Payment Successful!");
              if (!isFormDataStored) {
                await storeFormData("Payment Successful");
                isFormDataStored = true; // Mark data as stored
              }
            }
          } catch (err) {
            console.error(err);
            alert("Payment verification failed");
            if (!isFormDataStored) {
              await storeFormData("Payment Failed");
              isFormDataStored = true; // Mark data as stored
            }
          }
        },
        prefill: {
          name: formData.donorName,
          email: formData.email,
          contact: formData.mobile,
        },
        theme: { color: "#C61D21" },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", async function (response) {
        alert("Payment Failed: " + response.error.description);
        if (!isFormDataStored) {
          await storeFormData("Payment Failed");
          isFormDataStored = true; // Mark data as stored
        }
      });
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Failed to initiate payment. Please try again.");
      if (!isFormDataStored) {
        await storeFormData("Payment Failed");
        isFormDataStored = true; // Mark data as stored
      }
    }
  };

  const storeFormData = async (paymentStatus) => {
    const sheetDbURL = "https://sheetdb.io/api/v1/8y5xejo71udvy";
    const totalDonationAmount =
      (formData.selectedAnnaDaanAmount || 0) +
      (formData.selectedGitaDaanAmount || 0);

    const dataToStore = {
      paymentStatus, // New column
      annaDaanSeva: formData.annaDaanSeva ? "Yes" : "",
      gitaDaanSeva: formData.gitaDaanSeva ? "Yes" : "",
      selectedAnnaDaanAmount: formData.selectedAnnaDaanAmount || 0,
      selectedGitaDaanAmount: formData.selectedGitaDaanAmount || 0,
      totalDonationAmount,
      sevaDate: formData.sevaDate,
      honoureeName: formData.honoureeName,
      relationship: formData.relationship,
      donorName: formData.donorName,
      email: formData.email,
      mobile: formData.mobile,
      dob: formData.dob,
      receive80G: formData.receive80G,
      receiveMahaPrasadam: formData.receiveMahaPrasadam,
      ...(formData.receiveMahaPrasadam && {
        mahaPrasadamDetails: formData.mahaPrasadamDetails,
      }),
    };

    try {
      const response = await fetch(sheetDbURL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ data: dataToStore }),
      });

      if (response.ok) {
        console.log("Data stored successfully.");
      } else {
        alert("Error storing donor data.");
      }
    } catch (error) {
      console.error("Error storing data: ", error);
      alert("An error occurred while storing details. Please try again.");
    }
  };

  const getTotalAmount = () => {
    const annaAmount =
      formData.selectedAnnaDaanAmount ||
      parseFloat(formData.customAnnaDaanAmount) ||
      0;
    const gitaAmount =
      formData.selectedGitaDaanAmount ||
      parseFloat(formData.customGitaDaanAmount) ||
      0;
    return annaAmount + gitaAmount;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [isReadMoreVisible, setIsReadMoreVisible] = useState(false);

  const toggleReadMore = () => {
    setIsReadMoreVisible((prev) => !prev);
  };

  return (
    <>
      <Header />
      <div className="mahaKumbhMela">
        <div className="relative">
          <div className="bannerImg">
            <img src={BannerImgMob} className="xl:hidden" />
            <img src={BannerImgDesk} className="hidden xl:block object-cover" />
          </div>
          <div className="bannerData py-3 xl:absolute xl:top-1/2 xl:-translate-y-1/2 xl:px-8 ">
            <p className="text-sm xl:text-lg text-white text-shadow">
              Offer{" "}
              <span className="text-2xl xl:text-4xl fontTwo tracking-wide">
                Anna Daan seva
              </span>{" "}
              at
            </p>
            <p className="font-bold text-2xl xl:text-4xl text-white fontTwo text-shadow tracking-wide">
              MAHA
            </p>
            <p className="font-bold text-2xl xl:text-4xl text-white fontTwo text-shadow tracking-wide">
              KUMBH-MELA
            </p>
            <p className="font-bold text-sm xl:text-lg text-white fontTwo">
              14th JAN - 26th FEB
            </p>
            <p className="font-bold text-sm xl:text-lg text-white fontTwo">
              Support us feeding{" "}
              <span className="text-2xl xl:text-4xl tracking-wide">
                1 lakh + piligrims
              </span>{" "}
              daily
            </p>
            <p className="font-bold text-sm xl:text-lg text-white fontTwo">
              Avail 80(G) exemption
            </p>
          </div>
        </div>
        <div className="mainPage py-12 xl:pt-0">
          <div className="subBanner grid grid-cols-2 px-10 xl:px-0 gap-1 xl:flex">
            <div className="box">
              <img src={plateIcon} />
              <p>1 lakh + number of plates serverd daily</p>
            </div>
            <div className="box">
              <img src={clockIcon} />
              <p>11 AM TO 4 PM Annadaan Timing</p>
            </div>
            <div className="box">
              <img src={costIcon} />
              <p>INR 50 Cost per Meal</p>
            </div>
            <div className="box">
              <img src={iskconIcon} />
              <p>ISKCON Camp, sector 19, Gangoli Shivalaya Road</p>
            </div>
          </div>
          <div className="hidden xl:flex flex-col items-center pb-10">
            <img src={readMoreDesk} className="w-2/3" />
            <h3 className="text-black font-medium text-2xl w-[35%] text-center italic">
              “gaja turaga sahasraṁ gokulaṁ koṭi dānaṁ kanaka rajata pātram
              methini sāgarantham upaya kula viśuttam koṭi kanyā pradānaṁ nahi
              nahi bahu dānaṁ annadānaṁ samānam”
            </h3>
            <p className="px-3 text-lg text-center text-[#5B5555] w-2/3 mt-4">
              Donating 1000 elephants & horses, donating 10 million cows,
              donating any number of vessels of silver and gold, donating the
              entire land to sea, offering the entire services of the clan,
              helping in the marriage of 10 million women, all this is never
              ever equal to Anna Daan, the feeding of hungry and needy.
            </p>
            <div className="infoBox mx-20 mt-20">
              <div className="innerInfoBox">
                <h3 className="">Maha Kumbh Mela</h3>
                <p>
                  The Kumbh Mela, the world’s largest spiritual gathering, is a
                  festival of immense faith anddevotion. In 2025, Prayagraj will
                  host millions of devotees, with an expected 100 million
                  pilgrims (10 crores) seeking blessings, performing sacred
                  rituals, and connecting with the Supreme Lord.
                </p>
                <p>
                  As part of this holy occasion, ISKCON is organizing a Mega
                  Annadaan Drive to serve prasadam to the sadhus and devotees
                  attending the Mela. From January 14 to February 26, 2025, we
                  will distribute daily lakhs of plates of prasadam, along with
                  thousands of Bhagavad Gita copies shared for free.
                </p>
                <p>
                  We warmly invite you to join ISKCON in this mission to serve
                  and share the love of prasadam with all. By participating in
                  Annadaan Seva, you gain spiritual benefits and heartfelt
                  blessings, similar to taking a sacred dip in the holy waters
                  of the Kumbh Mela.This divine service pleases the Lord,
                  bringing immense joy and fulfillment.
                </p>
              </div>
              <div className="innerInfoBox">
                <h3>Anna Daan Seva</h3>
                <p>
                  ISKCON is organizing a large-scale Anna Daan programme to
                  serve millions of pilgrims at the Kumbh-Mela. We wish to set
                  up food distribution counters at a prominent location near the
                  sangam and serve free prasadam meals to 1 Lakh pilgrims
                  everyday.{" "}
                </p>
              </div>
              <div className="innerInfoBox">
                <h3>Donate Now</h3>
                <p>
                  Support us in our Annadaan seva for all the pilgrims during
                  the Maha Kumbh Mela. Serving Sri Krishna Maha prasadam to
                  these devotees will attract the Lord’s blessings and bring
                  joy, happiness and auspiciousness to your family.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:flex flex-row-reverse">
          <div className="mainPage xl:w-[42%] xl:pb-24">
            <div className="donationDetail mx-2 bg-[#C61D21] py-10 px-2 rounded-3xl xl:h-full">
              <form onSubmit={handleSubmit} className="text-white">
                <h2 className="text-center text-2xl xl:text-4xl">
                  Donation Details
                </h2>

                <div className="innerForm sevaContainer">
                  {/* Anna Daan Seva */}
                  <label>
                    <input
                      type="checkbox"
                      name="annaDaanSeva"
                      checked={formData.annaDaanSeva}
                      onChange={handleInputChange}
                      style={{ accentColor: "blue" }}
                    />
                    Anna Daan Seva
                  </label>
                  {formData.annaDaanSeva && (
                    <div className="grid grid-cols-2 -mt-4">
                      {[100000, 50000, 25000, 10000, 5000].map((amount) => (
                        <button
                          className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                          type="button"
                          key={amount}
                          onClick={() => handleAmountSelect("annaDaan", amount)}
                          style={{
                            backgroundColor:
                              formData.selectedAnnaDaanAmount === amount
                                ? "#fac785"
                                : "",
                            margin: "5px",
                          }}
                        >
                          ₹ {amount.toLocaleString("en-IN")}
                        </button>
                      ))}
                      <input
                        type="number"
                        name="customAnnaDaanAmount"
                        placeholder="Donate as you wish"
                        value={formData.customAnnaDaanAmount || ""}
                        onChange={handleInputChange}
                        className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount"
                      />
                    </div>
                  )}

                  {/* Gita Daan Seva */}
                  <label>
                    <input
                      type="checkbox"
                      name="gitaDaanSeva"
                      checked={formData.gitaDaanSeva}
                      onChange={handleInputChange}
                    />
                    Gita Daan Seva
                  </label>
                  {formData.gitaDaanSeva && (
                    <div className="grid grid-cols-2 -mt-4">
                      {[28080, 14040, 5460, 2860].map((amount) => (
                        <button
                          className="col-span-1 font-semibold p-3 bg-[#F9E7D0] rounded-xl text-black"
                          type="button"
                          key={amount}
                          onClick={() => handleAmountSelect("gitaDaan", amount)}
                          style={{
                            backgroundColor:
                              formData.selectedGitaDaanAmount === amount
                                ? "#fac785"
                                : "",
                            margin: "5px",
                          }}
                        >
                          ₹ {amount.toLocaleString("en-IN")}
                        </button>
                      ))}
                      <input
                        type="number"
                        name="customGitaDaanAmount"
                        placeholder="Donate as you wish"
                        value={formData.customGitaDaanAmount || ""}
                        onChange={handleInputChange}
                        className="col-span-2 p-3 border rounded-xl my-1 mx-1 customAmount"
                      />
                    </div>
                  )}

                  {/* Seva Date */}

                  <div>
                    <input
                      type="text"
                      name="sevaDate"
                      value={formData.sevaDate}
                      onChange={handleInputChange}
                      placeholder="Seva Date"
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                    />
                    {errors.sevaDate && <span>{errors.sevaDate}</span>}
                  </div>
                </div>

                <div className="innerForm">
                  <div className="text-center">
                    <h3>In memory of</h3>
                    <p className="text-white -mt-2">
                      I want my donation to be dedicated to:
                    </p>
                  </div>

                  <label className="xl:w-full">
                    <input
                      type="text"
                      name="honoureeName"
                      value={formData.honoureeName}
                      onChange={handleInputChange}
                      placeholder="Honouree Name"
                    />
                  </label>
                  <label className="xl:w-full">
                    <input
                      type="text"
                      name="relationship"
                      value={formData.relationship}
                      onChange={handleInputChange}
                      placeholder="Relationship"
                    />
                  </label>
                </div>

                <div className="innerForm">
                  <h3 className="text-center">Personal Details</h3>
                  <label className="xl:w-full">
                    <input
                      type="text"
                      name="donorName"
                      value={formData.donorName}
                      onChange={handleInputChange}
                      placeholder="Donor Name"
                      className="mt-2"
                    />
                    {errors.donorName && (
                      <span className="block -mt-2 ml-2">
                        {errors.donorName}
                      </span>
                    )}
                  </label>
                  <label className="xl:w-full">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email ID"
                    />
                    {errors.email && (
                      <span className="block -mt-2 ml-2">{errors.email}</span>
                    )}
                  </label>
                  <div className="flex gap-2">
                    <label className="flex-1">
                      <input
                        type="tel"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        placeholder="Mobile Number"
                        className="p-[10px] md:w-full"
                      />
                      {errors.mobile && (
                        <span className="block mt-1 ml-2">{errors.mobile}</span>
                      )}
                    </label>
                    <label className="flex-1">
                      <input
                        type="text"
                        name="dob"
                        value={formData.dob}
                        onChange={handleInputChange}
                        className="text-[#909192] p-2 border rounded-md"
                        placeholder="Date Of Birth"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                      />
                      {errors.dob && (
                        <span className="block -mt-2 ml-2">{errors.dob}</span>
                      )}
                    </label>
                  </div>
                </div>
                <p className="text-white mt-2 text-[10px]">
                  (Sankalpa and Archana will be performed for you on your
                  birthday)
                </p>

                <p className="text-white -mt-2 text-xs">
                  By continuing, you are agreeing to{" "}
                  <a className="underline text-[#EEF9A3]">
                    Terms of Use and Privacy Policy
                  </a>
                </p>

                {/* Additional checkboxes */}
                <div className="px-3 tandd mt-8">
                  <label>
                    <input
                      type="checkbox"
                      name="receive80G"
                      checked={formData.receive80G}
                      onChange={handle80GCheckboxChange}
                      style={{ accentColor: "blue" }}
                    />
                    I would like to receive 80(G) Certificate
                  </label>
                  {show80GDetails && (
                    <div>
                      <p className="text-white text-[8px] text-left ml-6">
                        Finance Act 2021 mandates uploading donation details for
                        80(G) deductions. Ensure PAN and address details are
                        provided.
                      </p>
                    </div>
                  )}

                  <label>
                    <input
                      type="checkbox"
                      name="receiveMahaPrasadam"
                      checked={formData.receiveMahaPrasadam}
                      onChange={handleMahaPrasadamCheckboxChange}
                      style={{ accentColor: "blue" }}
                    />
                    I would like to receive Maha prasadam (Only within India)
                  </label>
                  {showMahaPrasadamDetails && (
                    <div className="mt-3">
                      <input
                        type="text"
                        name="doorNumber"
                        placeholder="Door Number"
                        className="text-black"
                        value={formData.mahaPrasadamDetails.doorNumber}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              doorNumber: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="houseName"
                        placeholder="House / Apartment / Building Name"
                        value={formData.mahaPrasadamDetails.houseName}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              houseName: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="streetName"
                        placeholder="Street Name"
                        value={formData.mahaPrasadamDetails.streetName}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              streetName: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="area"
                        placeholder="Location / Area"
                        value={formData.mahaPrasadamDetails.area}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              area: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="country"
                        placeholder="Country"
                        value={formData.mahaPrasadamDetails.country}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              country: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="state"
                        placeholder="State"
                        value={formData.mahaPrasadamDetails.state}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              state: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="city"
                        placeholder="City"
                        value={formData.mahaPrasadamDetails.city}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              city: e.target.value,
                            },
                          }))
                        }
                      />
                      <input
                        type="text"
                        className="text-black"
                        name="pincode"
                        placeholder="Pincode"
                        value={formData.mahaPrasadamDetails.pincode}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            mahaPrasadamDetails: {
                              ...prev.mahaPrasadamDetails,
                              pincode: e.target.value,
                            },
                          }))
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="flex justify-center mt-8">
                  <button
                    type="submit"
                    className="bg-[#FAE29C] text-black px-12 py-3 rounded-2xl font-bold"
                  >
                    Donate{" "}
                    {getTotalAmount() > 0
                      ? "₹ " + getTotalAmount().toLocaleString("en-IN")
                      : ""}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="mainPage pt-20 xl:pt-0 xl:w-[58%]">
            <div className="xl:hidden readMore pt-20 flex flex-col items-center gap-6">
              <img src={readMoreMob} className="px-3" />
              <h3 className="text-black font-medium text-base w-4/5 text-center italic">
                “gaja turaga sahasraṁ gokulaṁ koṭi dānaṁ kanaka rajata pātram
                methini sāgarantham upaya kula viśuttam koṭi kanyā pradānaṁ nahi
                nahi bahu dānaṁ annadānaṁ samānam”
              </h3>

              <p className="px-3 text-[14px] text-center text-[#5B5555]">
                Donating 1000 elephants & horses, donating 10 million cows,
                donating any number of vessels of silver and gold, donating the
                entire land to sea, offering the entire services of the clan,
                helping in the marriage of 10 million women, all this is never
                ever equal to Anna Daan, the feeding of hungry and needy.
              </p>
              <button
                className="w-1/3 bg-[#FAE29C] text-bg py-3 rounded-2xl text-black"
                onClick={toggleReadMore}
              >
                {isReadMoreVisible ? "Read less" : "Read more"}
              </button>
              <div
                className={`readMoreContent transition-all duration-300 ease-in-out ${
                  isReadMoreVisible
                    ? "max-h-[120vh] opacity-100"
                    : "max-h-0 opacity-0"
                } overflow-hidden`}
              >
                <div className="infoBox mx-4">
                  <div className="innerInfoBox">
                    <h3 className="">Maha Kumbh Mela</h3>
                    <p>
                      The Kumbh Mela, the world’s largest spiritual gathering,
                      is a festival of immense faith anddevotion. In 2025,
                      Prayagraj will host millions of devotees, with an expected
                      100 million pilgrims (10 crores) seeking blessings,
                      performing sacred rituals, and connecting with the Supreme
                      Lord.
                    </p>
                    <p>
                      As part of this holy occasion, ISKCON is organizing a Mega
                      Annadaan Drive to serve prasadam to the sadhus and
                      devotees attending the Mela. From January 14 to February
                      26, 2025, we will distribute daily lakhs of plates of
                      prasadam, along with thousands of Bhagavad Gita copies
                      shared for free.
                    </p>
                    <p>
                      We warmly invite you to join ISKCON in this mission to
                      serve and share the love of prasadam with all. By
                      participating in Annadaan Seva, you gain spiritual
                      benefits and heartfelt blessings, similar to taking a
                      sacred dip in the holy waters of the Kumbh Mela.This
                      divine service pleases the Lord, bringing immense joy and
                      fulfillment.
                    </p>
                  </div>
                  <div className="innerInfoBox">
                    <h3>Anna Daan Seva</h3>
                    <p>
                      ISKCON is organizing a large-scale Anna Daan programme to
                      serve millions of pilgrims at the Kumbh-Mela. We wish to
                      set up food distribution counters at a prominent location
                      near the sangam and serve free prasadam meals to 1 Lakh
                      pilgrims everyday.{" "}
                    </p>
                  </div>
                  <div className="innerInfoBox">
                    <h3>Donate Now</h3>
                    <p>
                      Support us in our Annadaan seva for all the pilgrims
                      during the Maha Kumbh Mela. Serving Sri Krishna Maha
                      prasadam to these devotees will attract the Lord’s
                      blessings and bring joy, happiness and auspiciousness to
                      your family.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="impDates bg-[linear-gradient(to_bottom,_#C61D21,_#7D2628)] px-3 py-4 rounded-2xl mt-10 xl:mt-0 mx-3">
              <h2 className="text-center flex justify-center text-white">
                <span className="xl:w-3/5 xl:block">
                  Important Dates for Maha Kumbh Mela
                </span>
              </h2>
              <div className="dates grid grid-cols-2 xl:grid-cols-3 mt-10 gap-3 xl:mx-6">
                <div className="innerDate">
                  <div className="month">Jan</div>
                  <div className="dateInfo">
                    <p>PAUSH PURNIMA</p>
                    <p>Mon 13 , 2025</p>
                  </div>
                </div>
                <div className="innerDate">
                  <div className="month">Jan</div>
                  <div className="dateInfo">
                    <p>Makar Sankrati</p>
                    <p>Tue 14 , 2025</p>
                  </div>
                </div>
                <div className="innerDate">
                  <div className="month">Jan</div>
                  <div className="dateInfo">
                    <p>Mauni Amavasya</p>
                    <p>Mon 29 , 2025</p>
                  </div>
                </div>
                <div className="innerDate">
                  <div className="month">Feb</div>
                  <div className="dateInfo">
                    <p>Basant Panchami</p>
                    <p>Mon 3 , 2025</p>
                  </div>
                </div>
                <div className="innerDate">
                  <div className="month">Feb</div>
                  <div className="dateInfo">
                    <p>Achala Saptami</p>
                    <p>Tue 4 , 2025</p>
                  </div>
                </div>
                <div className="innerDate">
                  <div className="month">Feb</div>
                  <div className="dateInfo">
                    <p>Maghi Panchami</p>
                    <p>Wed 12 , 2025</p>
                  </div>
                </div>
                <div className="innerDate">
                  <div className="month">Feb</div>
                  <div className="dateInfo">
                    <p>Maha Shivratri</p>
                    <p>Tue 25 , 2025</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu mx-3 mt-6 pb-24">
              <img src={menuImgMob} className="w-full xl:hidden" />
              <img
                src={menuImgDesk}
                className="w-full hidden xl:block h-full"
              />
            </div>
          </div>
        </div>
        <div
          className=" bg-cover bg-center -mt-[3rem]"
          style={{ backgroundImage: `url(${thirdbg})` }}
        >
          {/* donation Through Bank */}
          <div className="mx-4 ">
            <div className="md:mx-44 md:border-5 border-2 md:rounded-2xl rounded-lg border-[#C61D21] mt-5 justify-center items-center  overflow-hidden">
              <h2 className="md:py-4 py-3 bg-[#C61D21] text-white text-center md:text-3xl text-base">
                Donation Through Bank (NEFT/RTGS){" "}
              </h2>
              <ul className=" flex flex-col justify-center xl:pl-80 md:pl-40 pl-0 md:items-start md:space-y-5 space-y-2 leading-4 my-4 items-center ">
                <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
                  <span className="md:text-2xl text-base text-[#C61D21] ">
                    Bank Name:<span className="text-black"> HDFC Bank</span>
                  </span>{" "}
                  <button
                    class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                    onClick={() => copyToClipboard("HDFC Bank", "bankName")}
                  >
                    {copiedText === "bankName" ? "Copied!" : "Copy"}
                  </button>
                </li>
                <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
                  <span className="md:text-2xl text-base text-[#C61D21]">
                    Account Name:<span className="text-black"> ISKCON</span>
                  </span>{" "}
                  <button
                    class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5  border-1 border-[#C61D21] ml-2 rounded-full"
                    onClick={() => copyToClipboard("ISKCON", "accountName")}
                  >
                    {copiedText === "accountName" ? "Copied!" : "Copy"}
                  </button>
                </li>
                <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
                  <span className=" text-[#C61D21]">
                    Account Number:{" "}
                    <span className="text-black">50100436709020</span>
                  </span>{" "}
                  <button
                    class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                    onClick={() =>
                      copyToClipboard("50100436709020", "accountNumber")
                    }
                  >
                    {copiedText === "accountNumber" ? "Copied!" : "Copy"}
                  </button>
                </li>
                <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal ">
                  <span className=" text-[#C61D21]">
                    IFSC Code: <span className="text-black">HDFC00568</span>
                  </span>
                  <button
                    class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                    onClick={() => copyToClipboard("HDFC00568", "ifscCode")}
                  >
                    {copiedText === "ifscCode" ? "Copied!" : "Copy"}
                  </button>
                </li>
                <li className="w-full md:text-2xl text-base leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
                  <span className=" text-[#C61D21]">
                    Branch:{" "}
                    <span className="text-black">ONE TOWN VIJAYAWADA </span>
                  </span>{" "}
                  <button
                    class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                    onClick={() =>
                      copyToClipboard("ONE TOWN VIJAYAWADA", "branch")
                    }
                  >
                    {copiedText === "branch" ? "Copied!" : "Copy"}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          {/* UPI Payment */}
          <div className="mx-4 ">
            <div className="md:mx-44  md:border-5 border-2 md:rounded-2xl rounded-lg border-[#C61D21] mt-10 overflow-hidden">
              <h2 className="md:py-4 py-2 bg-[#C61D21] text-white text-center md:text-3xl text-base">
                Donate Through UPI
              </h2>

              <div className="flex md:flex-row flex-col justify-around items-center pt-3 md:pb-3 pb-4 gap-4">
                <div className="md:block flex gap-2 px-1">
                  <h2 className="md:text-2xl text-sm text-[#C61D21]">
                    UPI ID:
                  </h2>
                  <p className="md:text-xl text-sm  text-black">
                    {" "}
                    ISCKONVIJAYAWADA@IDFCBANK
                  </p>
                </div>
                <img src={barcode1} alt="" width="200px" height="200px" />
                <span className="font-bold">OR</span>
                <img src={barcode2} alt="" width="200px" height="200px" />
              </div>
            </div>

            <div className="text-left md:mx-44 mt-5 pb-10">
              <h2 className="text-3xl  text-[#C61D21] text-left font-bold">
                After Donation:
              </h2>
              <p className="md:text-2xl mt-4 text-base text-black w-full text-justify font-semibold">
                Once you have donated, please share the transaction details or
                receipt via WhatsApp at{" "}
                <span className="text-red">+91 9955685568</span>. This helps us
                acknowledge your contribution and issue a receipt for tax
                exemption under Section 80G of the Income Tax Act.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MahaKumbhMela;
