import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import mainImg from "./assets/main-img.jpg";
import annadan from "./assets/annadan.png";
import gita from "./assets/gita.png";
import kirtan from "./assets/kirtan.png";
import pushpaMaha from "./assets/pushpaMaha.png";
import barcode1 from "./assets/barcode1.jpg";
import barcode2 from "./assets/barcode2.jpg";
import gulabiImg from "./assets/gulabi.png";
import lotusImg from "./assets/lotus.png";
import jasmineImg from "./assets/jasmine.png";
import marigoldImg from "./assets/marigold.png";
import whiteFlowerImg from "./assets/whiteFlower.png";
import lilyImg from "./assets/lily.png";
import axios from "axios";

const AnvIskcon = () => {
  // Flower Section
  const [quantities, setQuantities] = useState({});
  // Flower data
  const flowers = [
    {
      id: 1,
      name: "Marigold flower",
      price: 70,
      unit: "kg",
      image: marigoldImg,
    },
    {
      id: 2,
      name: "Jasmine flower",
      price: 600,
      unit: "kg",
      image: jasmineImg,
    },
    {
      id: 3,
      name: "Gulabi flower",
      price: 200,
      unit: "kg",
      image: gulabiImg,
    },
    {
      id: 4,
      name: "Lily flower",
      price: 300,
      unit: "kg",
      image: lilyImg,
    },
    {
      id: 5,
      name: "Chrysanthemum flower",
      price: 600,
      unit: "Kg",
      image: whiteFlowerImg,
    },
    {
      id: 6,
      name: "Lotus flower",
      price: 240,
      unit: "Dozen",
      image: lotusImg,
    },
  ];

  // Adjust quantity for a specific flower
  const adjustQuantity = (id, change) => {
    setQuantities((prev) => {
      const newQuantity = (prev[id] || 0) + change;
      return { ...prev, [id]: newQuantity > 0 ? newQuantity : 0 };
    });
  };

  // Payment options with amount and plate count
  const [amount, setAmount] = useState("");
  const paymentOptions = [
    {
      amount: 550000,
      description: "One Day Anna Daan Seva",
      isHighlight: true,
    },
    { amount: 27500, plates: 500 },
    { amount: 11000, plates: 200 },
    { amount: 5500, plates: 100 },
    { amount: 3300, plates: 60 },
    { amount: 2200, plates: 40 },
    { amount: 1100, plates: 20 },
  ];

  // Copy Text
  const [copiedText, setCopiedText] = useState(null);

  const copyToClipboard = async (text, id) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        setCopiedText(id);
        setTimeout(() => setCopiedText(null), 2000);
      } else {
        alert("Copy functionality is not supported in this browser.");
      }
    } catch (error) {
      console.error("Failed to copy text: ", error);
      alert("Failed to copy. Please try again.");
    }
  };

  // Event Data
  const eventData = [
    {
      id: 1,
      title: "Pushpa Maha Abhisekham ",
      image: pushpaMaha,
    },
    {
      id: 2,
      title: "Maha Annadan ",
      image: annadan,
    },
    {
      id: 3,
      title: "Bhagavad Gita Class",
      image: gita,
    },
    {
      id: 4,
      title: "Kirtan",
      image: kirtan,
    },
  ];

  const [show80GDetails, setShow80GDetails] = useState(false);
  const [showMahaPrasadamDetails, setShowMahaPrasadamDetails] = useState(false);
  const handle80GCheckboxChange = (e) => {
    setShow80GDetails(e.target.checked);
    handleChange(e);
  };

  const handleMahaPrasadamCheckboxChange = (e) => {
    setShowMahaPrasadamDetails(e.target.checked);
    handleChange(e);
  };

  // Form Handling
  const [formData, setFormData] = useState({
    honoreeName: "",
    relationship: "",
    donorName: "",
    email: "",
    totalAmount: 0,
    mobileNumber: "",
    dateOfBirth: "",
    receivePrasadam: false,
    receiveCertificate: false,
    doorNumber: "",
    houseName: "",
    streetName: "",
    area: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleMahaPrasadamDetailsChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      mahaPrasadamDetails: {
        ...prevData.mahaPrasadamDetails,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (
      formData.totalAmount === 0 ||
      formData.mobileNumber === "" ||
      formData.email === "" ||
      formData.honoreeName === ""
    ) {
      alert("Please enter all required fields");
      return;
    }

    let isFormDataStored = false; // Prevent duplicate storage

    let description = "Annadaan Seva";
    // Default description for monetary donation
    if (quantities && Object.keys(quantities).length > 0) {
      // If flower quantities exist, add them to the description
      const flowerDescriptions = flowers
        .filter((flower) => quantities[flower.id] > 0) // Check if any flower quantity is selected
        .map(
          (flower) => `${flower.name} (${quantities[flower.id]} ${flower.unit})`
        );
      description = `Annadaan Seva and Flower Seva: ${flowerDescriptions.join(
        ", "
      )}`;
    }
    try {
      // Create order on backend
      const response = await axios.post(
        "http://localhost:5000/api/payment/create-order",
        {
          amount: formData.totalAmount,
          currency: "INR",
        }
      );

      const order = response.data;
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: formData.totalAmount * 100,
        currency: "INR",
        name: "ISKCON 23rd ANV. Donation",
        description: description,
        order_id: order.id,
        notes: {
          honoreeName: formData.honoreeName,
          donorName: formData.donorName,
          donorEmail: formData.email,
          mobileNumber: formData.mobileNumber,
          SevaDate: new Date().toISOString(),
        },
        handler: async function (response) {
          try {
            // Verify payment
            const verificationResponse = await axios.post(
              "http://localhost:5000/api/payment/verify-payment",
              {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                donationDetails: { ...formData },
              }
            );

            if (verificationResponse.data.success) {
              alert("Payment Successful!");
              await storeFormData("Payment Successful");
              isFormDataStored = true;
            }
          } catch (err) {
            console.error(err);
            alert("Payment verification failed");
            if (!isFormDataStored) {
              await storeFormData("Payment Failed");
              isFormDataStored = true;
            }
          }
        },
        prefill: {
          name: formData.donorName,
          email: formData.email,
          contact: formData.mobileNumber,
        },
        theme: { color: "#C61D21" },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", async function (response) {
        alert("Payment Failed: " + response.error.description);
        if (!isFormDataStored) {
          await storeFormData("Payment Failed");
          isFormDataStored = true;
        }
      });
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Failed to initiate payment. Please try again.");
      if (!isFormDataStored) {
        await storeFormData("Payment Failed");
        isFormDataStored = true;
      }
    }
  };

  const storeFormData = async (paymentStatus) => {
    // Construct form data object
    const storingFormData = {
      // Basic donation info
      paymentStatus,
      amount: formData.totalAmount,

      // Donor and Honoree info
      honoreeName: formData.honoreeName,
      relationship: formData.relationship,
      donorName: formData.donorName,
      email: formData.email,
      mobileNumber: formData.mobileNumber,
      dateOfBirth: formData.dateOfBirth,

      // Additional preferences
      receivePrasadam: formData.receivePrasadam ? "Yes" : "No",
      receiveCertificate: formData.receiveCertificate ? "Yes" : "No",
      doorNumber: formData.doorNumber,
      houseName: formData.houseName,
      streetName: formData.streetName,
      area: formData.area,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      pincode: formData.pincode,

      // Timestamp
      SevaDate: new Date().toISOString(),
    };

    const formBody = new URLSearchParams(storingFormData).toString();

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxAU3Ns1uc4DBwaD0x1Q20t9Bo4LwU8J4W4LHE9JWZPuoFYIk4QVO45rEO1lA3qEqMl/exec",
        {
          method: "POST",
          body: formBody,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      // Reset form data after successful submission
      setFormData({
        honoreeName: "",
        relationship: "",
        donorName: "",
        email: "",
        totalAmount: 0,
        mobileNumber: "",
        dateOfBirth: "",
        receivePrasadam: false,
        receiveCertificate: false,
        doorNumber: "",
        houseName: "",
        streetName: "",
        area: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      });

      setQuantities({});
      setAmount("");
    } catch (error) {
      console.error("Form submission error:", error);
      alert("Some error occured");
    }
  };

  const getTotalAmount = () => {
    const validAmount = Number(amount) || 0;
    const validQuantities = quantities || {};

    return (
      validAmount +
      Object.keys(validQuantities).reduce((acc, id) => {
        const flower = flowers.find((f) => f.id === Number(id));
        return acc + (validQuantities[id] || 0) * (flower?.price || 0);
      }, 0)
    );
  };

  // useEffect to update totalAmount in formData
  useEffect(() => {
    const totalAmount = getTotalAmount();
    setFormData((prev) => ({
      ...prev,
      totalAmount,
    }));
  }, [amount, quantities, flowers]);
  // console.log("Total Amount:", getTotalAmount());

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Header />
      <div className="w-full gita-container flex flex-col justify-center items-center space-y-5 md:space-y-10">
        {/* First Section */}
        <div className="w-full">
          <img src={mainImg} alt="main Img" className="w-[100vw]" />
        </div>

        {/* Second Section */}
        <div className="p-2 text-center">
          <p className="text-black text-base lg:text-4xl font-semibold lg:font-bold">
            ISKCON Vijayawada Celebrates 23rd Anniversary  on Vasantha Panchami!
          </p>
          <p className="text-black lg:text-lg">
            This Vasantha Panchami, on February 2, 2025, join us in celebrating
            a monumental milestone — the 23rd Anniversary of ISKCON Sri Radha
            Shyam Sundar Mandir. Situated in the heart of Vijayawada, this
            sacred temple has been a beacon of spirituality, peace, and devotion
            for hundreds of visitors every day.
          </p>
          <p className="text-black lg:text-lg">
            This year’s celebration promises to be a grand occasion filled with
            vibrant festivities, divine rituals, and an outpouring of love for
            Sri Radha Shyam Sundar.
          </p>
        </div>

        {/* Third Section */}
        <div>
          <p className="text-3xl text-center lg:text-4xl text-black font-semibold lg:font-bold mb-5">
            Event Highlights
          </p>
          <div className="w-[100%] space-y-6 lg:space-y-0 lg:gap-6 flex lg:grid lg:grid-cols-2 flex-col items-center justify-center">
            {eventData.map((event) => (
              <div
                className="w-[95%] lg:w-[100%] bg-[#C61D21] flex flex-col justify-center items-center p-3 rounded-xl lg:rounded-2xl space-y-4 border-4 border-solid border-[#FFB700]"
                key={event.id}
              >
                <img src={event.image} alt={event.title} />
                <p className="text-white text-xl lg:text-2xl font-semibold mb-3">
                  {event.title}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Fourth Section */}
        <div className="mt-5 mb-2 w-[100%] flex flex-col items-center justify-center bg-[#C61D21] p-1">
          <h1 className="text-white text-3xl md:text-5xl font-semibold mb-4 p-2">
            Annadaan Seva
          </h1>

          {/* cenceled */}
          {/* <button
            onClick={() => setAmount(paymentOptions[0].amount)}
            className="w-full lg:w-[95%] bg-[#F9E7D0] text-center rounded-xl focus:outline-none transition"
          >
            <p className="text-black text-2xl font-bold">
              ₹{paymentOptions[0].amount.toLocaleString()}
            </p>
            <p className="text-black text-lg">
              ({paymentOptions[0].description})
            </p>
          </button> */}

          <div className="grid grid-cols-2 gap-2 mt-2 w-full lg:w-[95%]">
            {paymentOptions.slice(1).map((option, index) => (
              <button
                key={index}
                onClick={() => setAmount(option.amount)}
                className="bg-[#F9E7D0] text-red-800 text-center py-1 rounded-xl focus:bg-[#FAC785] focus:outline-none transition"
              >
                <p className="text-black text-lg lg:text-xl font-bold">
                  ₹{option.amount.toLocaleString()}
                </p>
                <p className="text-black text-sm lg:text-lg">
                  ({option.plates} Plates)
                </p>
              </button>
            ))}
          </div>

          {/* Input Box */}
          <div className="my-6 w-full lg:w-[95%] rounded-xl">
            <input
              type="text"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="w-full text-lg px-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
              placeholder="₹ Donate As You Wish"
            />
          </div>
        </div>

        {/* Fifth Section */}
        <div className="w-[100%] lg:w-[95%] flex flex-col items-center justify-center bg-[#E2CFAF] text-center lg:border-4 border-solid border-[#C61D21]">
          <h1 className="w-[100%] text-white bg-[#C61D21] text-2xl lg:text-4xl font-bold mb-6 p-2">
            Pushpa Abhishek Seva
          </h1>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-3 w-full lg:w-[98%] p-1 lg:mb-3">
            {flowers.map((flower) => (
              <div
                key={flower.id}
                className="border-4 border-solid  border-[#C61D21] rounded-lg shadow-md flex flex-col items-center"
              >
                <h2 className="w-[100%] text-white bg-[#C61D21] text-sm lg:text-xl p-1 lg:p-2 font-bold mb-2">
                  {flower.name}
                </h2>
                <img
                  src={flower.image}
                  alt={flower.name}
                  className="object-contain rounded-lg mb-4 p-1"
                />
                <p className="text-red-700 text-lg lg:text-xl text-black font-semibold mb-4">
                  Per {flower.unit}: ₹{flower.price}
                </p>
                <div className="flex items-center gap-2 lg:mb-2">
                  <button
                    onClick={() => adjustQuantity(flower.id, -1)}
                    className="bg-white text-black px-3 py-1 text-lg lg:text-xl rounded-md border-solid border-1 border-black"
                  >
                    -
                  </button>
                  <span className="bg-white rounded-md m-1 px-3 py-1  text-lg lg:text-xl font-bold border-solid border-1 border-black">
                    {quantities[flower.id] || 0}
                  </span>
                  <button
                    onClick={() => adjustQuantity(flower.id, 1)}
                    className="bg-white text-black px-3 py-1 text-lg lg:text-xl rounded-md  border-solid border-1 border-black"
                  >
                    +
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Sixth Section */}
        <div className="w-full flex flex-col items-center justify-center p-6">
          <form className="lg:w-[70%] border-3 border-solid border-[#C61D21] bg-[#F9E7D0] rounded-lg p-2 shadow-lg">
            <h2 className="text-2xl font-bold mb-2 text-center">
              In memory of
            </h2>
            <p className="w-[100%] text-lg text-black text-center mb-4">
              I want my donation to be dedicated to:
            </p>

            <div className="mb-4">
              <input
                type="text"
                name="honoreeName"
                value={formData.honoreeName}
                onChange={handleChange}
                placeholder="Honouree Name"
                className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none focus:ring-2 focus:ring-yellow-400"
              />
            </div>

            <div className="mb-4">
              <input
                type="text"
                name="relationship"
                value={formData.relationship}
                onChange={handleChange}
                placeholder="Relationship"
                className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none focus:ring-2 focus:ring-yellow-400"
              />
            </div>

            <h2 className="text-xl font-bold mt-6 mb-4 text-center">
              Personal Details
            </h2>

            <div className="mb-4">
              <input
                type="text"
                name="donorName"
                value={formData.donorName}
                onChange={handleChange}
                placeholder="Donor Name *"
                className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none focus:ring-2 focus:ring-yellow-400"
                required
              />
            </div>

            <div className="mb-4">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email ID"
                className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none focus:ring-2 focus:ring-yellow-400"
                required
              />
            </div>

            <div className="mb-4">
              <input
                type="tel"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                placeholder="Mobile Number *"
                className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none focus:ring-2 focus:ring-yellow-400"
                required
              />
            </div>

            <div className="mb-4">
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleChange}
                className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none focus:ring-2 focus:ring-yellow-400"
              />
              <p className="text-sm lg:text-lg text-black mt-1">
                (Sankalpa and Archana will be performed for you on your
                birthday)
              </p>
            </div>

            <div className="mb-2 flex items-center">
              <input
                type="checkbox"
                name="receiveCertificate"
                checked={formData.receiveCertificate}
                onChange={handle80GCheckboxChange}
                className="mr-2"
              />
              <label className="text-sm lg:text-lg">
                I would like to receive 80(G) Certificate
              </label>
            </div>
            {show80GDetails && (
              <div>
                <p className="text-black text-sm lg:text-base text-left">
                  Finance Act 2021 mandates uploading donation details for 80(G)
                  deductions. Ensure PAN and address details are provided.
                </p>
              </div>
            )}

            <div className="mb-3 flex items-center">
              <input
                type="checkbox"
                name="receivePrasadam"
                checked={formData.receivePrasadam}
                onChange={handleMahaPrasadamCheckboxChange}
                className="mr-2"
              />
              <label className="text-sm lg:text-lg">
                I would like to receive Maha prasadam (Only within India)
              </label>
            </div>
            {showMahaPrasadamDetails && (
              <div className="my-3 flex flex-col space-y-2">
                <input
                  type="text"
                  name="doorNumber"
                  placeholder="Door Number"
                  className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none"
                  value={formData.doorNumber}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none"
                  name="houseName"
                  placeholder="House / Apartment / Building Name"
                  value={formData.houseName}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none"
                  name="streetName"
                  placeholder="Street Name"
                  value={formData.streetName}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none"
                  name="area"
                  placeholder="Location / Area"
                  value={formData.area}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none"
                  name="city"
                  placeholder="City"
                  value={formData.city}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none"
                  name="state"
                  placeholder="State"
                  value={formData.state}
                  onChange={handleChange}
                />

                <input
                  type="text"
                  className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none"
                  name="country"
                  placeholder="Country"
                  value={formData.country}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  className="w-full bg-white text-black px-4 py-2 rounded-lg outline-none"
                  name="pincode"
                  placeholder="Pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
              </div>
            )}

            <p className="text-xs lg:text-lg text-gray-400 mb-2">
              By continuing, you are agreeing to our{" "}
              <a href="#" className="text-yellow-500 underline">
                Terms of Use
              </a>{" "}
              and{" "}
              <a href="#" className="text-yellow-500 underline">
                Privacy Policy
              </a>
              .
            </p>
          </form>
          <button
            onClick={handleSubmit}
            className="w-[100%] lg:w-[30%] lg:text-xl bg-yellow-500 text-black font-bold py-2 mt-3 rounded-lg hover:bg-yellow-600"
          >
            Donate {formData.totalAmount ? `₹${formData.totalAmount}` : ""}
          </button>
        </div>

        {/* Seventh Section */}
        <div className="w-[90%] md:w-[70%] border-solid border-2 border-[#C61D21] rounded-lg">
          <h2 className="md:py-4 py-3 bg-[#C61D21] text-white text-center md:text-3xl text-base">
            Donation Through Bank (NEFT/RTGS){" "}
          </h2>
          <ul className=" flex flex-col justify-center xl:pl-80 md:pl-40 pl-0 md:items-start md:space-y-5 space-y-2 leading-4 my-4 items-center ">
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className="md:text-2xl text-base text-[#C61D21] ">
                Bank Name:<span className="text-black"> HDFC Bank</span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("HDFC Bank", "bankName")}
              >
                {copiedText === "bankName" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className="md:text-2xl text-base text-[#C61D21]">
                Account Name:<span className="text-black"> ISKCON</span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5  border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("ISKCON", "accountName")}
              >
                {copiedText === "accountName" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className=" text-[#C61D21]">
                Account Number:{" "}
                <span className="text-black">50100436709020</span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() =>
                  copyToClipboard("50100436709020", "accountNumber")
                }
              >
                {copiedText === "accountNumber" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base  leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal ">
              <span className=" text-[#C61D21]">
                IFSC Code: <span className="text-black">HDFC00568</span>
              </span>
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("HDFC00568", "ifscCode")}
              >
                {copiedText === "ifscCode" ? "Copied!" : "Copy"}
              </button>
            </li>
            <li className="w-full md:text-2xl text-base leading-2 tracking-none font-bold text-black px-2 md:px-0 flex justify-between md:justify-normal">
              <span className=" text-[#C61D21]">
                Branch: <span className="text-black">ONE TOWN VIJAYAWADA </span>
              </span>{" "}
              <button
                class="bg-gray-200 md:text-xs text-[10px] px-2 md:py-0.5 border-1 border-[#C61D21] ml-2 rounded-full"
                onClick={() => copyToClipboard("ONE TOWN VIJAYAWADA", "branch")}
              >
                {copiedText === "branch" ? "Copied!" : "Copy"}
              </button>
            </li>
          </ul>
        </div>

        {/* Eighth Section */}
        <div className="mb-3 w-[90%] md:w-[70%] md:mx-44  md:border-5 border-2 md:rounded-2xl rounded-lg border-[#C61D21] mt-10 overflow-hidden">
          <h2 className="md:py-4 py-2 bg-[#C61D21] text-white text-center md:text-3xl text-base">
            Donate Through UPI
          </h2>

          <div className="flex md:flex-row flex-col justify-around items-center pt-3 md:pb-3 pb-4 gap-4">
            <div className="md:block flex gap-2">
              <h2 className="md:text-2xl text-base text-[#C61D21]">UPI ID:</h2>
              <p className="md:text-xl text-base  text-black">
                {" "}
                ISCKONVIJAYAWADA@IDFCBANK
              </p>
            </div>
            <img src={barcode1} alt="" width="200px" height="200px" />
            <p className="lg:text-3xl text-lg font-semibold text-[#C61D21]">
              OR
            </p>
            <img src={barcode2} alt="" width="200px" height="200px" />
          </div>
        </div>

        {/* Ninth Section */}
        <div className="text-left md:mx-44 pb-10 p-2 mb-5">
          <h2 className="text-3xl  text-[#C61D21] text-left font-bold">
            After Donation:
          </h2>
          <p className="md:text-2xl mt-4 text-base text-black w-full text-justify font-semibold">
            Once you have donated, please share the transaction details or
            receipt via WhatsApp at{" "}
            <span className="text-red">+91 9955685568</span>. This helps us
            acknowledge your contribution and issue a receipt for tax exemption
            under Section 80G of the Income Tax Act.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AnvIskcon;
