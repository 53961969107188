// import React, { useEffect } from "react";
// import "./styles.css";
// import gopala from "./assets/gopala1.png";
// import gopala2 from "./assets/gopala2.jpg";
// import mobPage from "./assets/mob-page.jpg";
// import Header from "../../../Components/Header/Header";
// import Footer from "../../../Components/Footer/Footer";
// function GopalaForSchool() {
//   useEffect(() => {
//     // Scroll to top on component mount
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <div className="w-full bg-[#EDD9CB]">
//       <Header />
//       <div className="desktop w-full md:mt-8">
//         <img src={gopala} alt="" />
//         <img src={gopala2} alt="" />
//       </div>

//       {/* <div className='mobile w-full'>
//         <img src={mobPage} alt="" />
//       </div> */}
//       <Footer />
//     </div>
//   );
// }

// export default GopalaForSchool;

import React, { useEffect } from "react";
import "./styles.css";

import Footer from "../../../Components/Footer/Footer";
import Header from "../../../Components/Header/Header";
import grpImg from "./assets/Group 1707480031 (1).png";
import background from "./assets/background.png";
import headingImg from "./assets/firstThreeCombinedDesktopBg.png";
import Diya from "./assets/Vector (10).png";

import top from "./assets/floral-lotus-mandala-clipart-black-vector-art-600x600-removebg-preview 1.png";
import bottom from "./assets/floral-lotus-mandala-clipart-black-vector-art-600x600-removebg-preview 2.png";

function YouthSeminar() {
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />

      <div className="w-full relative md:mt-12">
        {/* Background Pattern Container */}

        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center -z-30"
          style={{ backgroundImage: `url(${headingImg})` }}
        />

        {/* Content Container */}
        <div className="relative z-10 max-w-[100%] md:max-w-[90%] mx-auto px-4 py-2">
          {/* Seminar Badge */}
          <div className="flex justify-center mb-8">
            <div className="bg-red text-white px-8 py-3 rounded-b-xl text-xl md:text-2xl font-bold border-2 border-white">
              GOPALA FUN SCHOOL
            </div>
          </div>

          {/* Main Title */}
          <div className="text-center mb-6">
            <h2 className="text-4xl md:text-6xl font-bold text-red mb-1">
              Welcome to Gopala Fun School!
            </h2>
          </div>

          {/* First Paragraph */}
          <div className="mb-8 text-lg md:text-2xl leading-relaxed text-gray-800">
            <p className="text-justify">
              At ISKCON Vijayawada, we believe in nurturing the spirit and mind
              of children through a blend of spiritual education and fun
              activities. Our Gopala Fun School is dedicated to providing a
              holistic learning environment where children can explore their
              creativity, develop essential life skills, and deepen their
              understanding of spirituality through the teachings of Lord
              Krishna.
            </p>
          </div>
          <div className="mb-8 leading-relaxed text-gray-800">
            <img
              src={grpImg}
              className="h-56 md:h-[100%] shadow-[18px_20px_10px_#e09c89,_0_6px_6px_rgba(0,0,0,0.1),_0_0_0_1px_rgba(255,228,214,0.7)]"
            />
          </div>
        </div>
      </div>

      <div className="relative">
        <div
          className="absolute inset-0 bg-cover bg-no-repeat bg-center opacity-70 -z-30"
          style={{ backgroundImage: `url(${background})` }}
        />
        <div className="max-w-[100%] md:max-w-[90%] mx-auto px-4 py-8">
          {/* Vision Section */}
          <div className="mb-12">
            <h2 className="text-[#C61D21] text-4xl md:text-5xl font-bold mb-4">
              Our Vision
            </h2>
            <p className="text-gray-800 text-lg md:text-2xl leading-relaxed">
              The Gopala Fun School Aims To Instill Values Of Compassion,
              Teamwork, And Devotion In Children While Making Learning
              Enjoyable. We Envision A Generation Of Children Who Are Not Only
              Knowledgeable But Also Spiritually Aware And Socially Responsible.
            </p>
          </div>

          {/* What We Offer div */}
          <div>
            <h2 className="text-[#C61D21] text-4xl md:text-5xl font-bold mb-6">
              What We Offer
            </h2>

            {/* Interactive Learning Environment */}
            <div className="mb-8">
              <h3 className="text-lg md:text-2xl font-bold mb-3">
                Interactive Learning Environment:
              </h3>
              <ul className="space-y-4">
                <li className="text-gray-800 text-lg md:text-2xl leading-relaxed">
                  • Engaging Curriculum: Our Curriculum Combines Traditional
                  Learning With Hands-On Activities, Encouraging Children To
                  Explore Subjects Like Ethics, Values, And Spirituality Through
                  Fun And Interactive Lessons.
                </li>
                <li className="text-gray-800 text-lg md:text-2xl leading-relaxed">
                  • Creative Activities: Children Participate In Art, Music,
                  Dance, And Storytelling That Promote Self-Expression And
                  Creativity.
                </li>
              </ul>
            </div>

            {/* Spiritual Education */}
            <div className="mb-8">
              <h3 className="text-lg md:text-2xl font-bold mb-3">
                Spiritual Education:
              </h3>
              <ul className="space-y-4">
                <li className="text-gray-800 text-lg md:text-2xl leading-relaxed">
                  • Bhagavad Gita For Kids: We Introduce Children To The
                  Teachings Of The Bhagavad Gita In An Age-Appropriate Manner,
                  Helping Them Understand Core Values And Principles.
                </li>
                <li className="text-gray-800 text-lg md:text-2xl leading-relaxed">
                  • Krishna Consciousness: Activities Designed To Teach Children
                  About Lord Krishna's Life And Teachings, Fostering A Sense Of
                  Devotion And Love For God.
                </li>
              </ul>
            </div>

            {/* Physical Development */}
            <div>
              <h3 className="text-lg md:text-2xl font-bold mb-3">
                Physical Development:
              </h3>
              <ul className="space-y-4">
                <li className="text-gray-800 text-lg md:text-2xl leading-relaxed">
                  • Yoga And Meditation: Regular Yoga Sessions Help Children
                  Develop Physical Fitness, Concentration, And Mindfulness.
                </li>
                <li className="text-gray-800 text-lg md:text-2xl leading-relaxed">
                  • Games And Sports: Fun Physical Activities Promote Teamwork,
                  Cooperation, And A Healthy Lifestyle.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="relative bg-[#c61d21] text-white min-h-[30vh] overflow-hidden w-full box-border px-2 md:px-4 py-4">
          <div className="relative z-10 max-w-[100%] md:max-w-[90%] mx-auto">
            <div className="w-full">
              <h1 className="text-center font-['Source_Serif_4'] font-semibold text-[#f3b01b] mb-3 text-[32px] leading-[1.2] sm:text-[54px] lg:text-[70px]">
                Why Choose Gopala Fun School?
              </h1>
              <ul className="list-disc mx-auto my-2 px-4 md:px-5 leading-[1.5] text-[#ffdebd] text-[16px] text-justify sm:text-[26px] sm:leading-[1.8] sm:px-10 lg:text-[32px] lg:leading-[2] lg:my-[30px] lg:max-w-[1600px]">
                <li className="my-[15px] sm:my-[20px]">
                  <strong>Experienced Educators: </strong>Our teachers are
                  trained in both academic and spiritual teachings, ensuring a
                  balanced approach to education.
                </li>
                <li className="my-[15px] sm:my-[20px]">
                  <strong>Safe and Nurturing Environment: </strong> We provide a
                  safe space where children feel valued, loved, and encouraged
                  to explore their potential.
                </li>
                <li className="my-[15px] sm:my-[20px]">
                  <strong>Community Engagement: </strong>We foster a sense of
                  community among families, encouraging parents to participate
                  in school activities and events
                </li>
                <h2 className="text-[#ffdebd] my-[20px] sm:my-[30px]">
                  Enroll Your Child Today!
                </h2>
                <p className="text-[#f3b01b] font-semibold my-[20px] sm:my-[30px]">
                  Join us at Gopala Fun School and give your child the
                  opportunity to learn, grow, and thrive in a nurturing
                  environment that values education and spirituality.
                </p>
              </ul>
            </div>
          </div>
          <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none">
            <img
              className="absolute top-[-25px] left-[-25px] w-[140px] h-auto opacity-100 sm:top-[-50px] sm:left-[-50px] sm:w-[280px] lg:top-[-75px] lg:left-[-75px] lg:w-[420px]"
              src={top}
              alt="Top Left Design"
              loading="lazy"
            />
            <img
              className="absolute bottom-[-28px] right-[-20px] w-[233px] h-auto opacity-100 sm:bottom-[-56px] sm:right-[-39px] sm:w-[466px] lg:bottom-[-84px] lg:right-[-58px] lg:w-[699px]"
              src={bottom}
              alt="Bottom Right Design"
              loading="lazy"
            />
          </div>
        </div>

        <div className="max-w-[100%] md:max-w-[80%] mx-auto p-6">
          <div className="border-2 border-[#C61D21] rounded-xl overflow-hidden">
            {/* Header */}
            <div className="bg-[#C61D21] text-white text-center py-4 text-xl md:text-2xl font-semibold">
              For Registration and Inquiries
            </div>

            {/* Content */}
            <div className="bg-[#feead2] p-8 flex flex-col items-center gap-8">
              <button
                variant="destructive"
                size="lg"
                className="bg-[#C61D21] text-lg md:text-2xl text-white font-semibold rounded-lg hover:bg-[#a01830]  px-8 py-2"
              >
                Click Here
              </button>

              <p className="text-[#c41e3a] text-lg md:text-2xl text-center font-semibold max-w-2xl">
                <img
                  src={Diya}
                  alt="Lotus"
                  className="inline-block mr-2 w-8 h-6 md:w-10 md:h-8"
                />
                Together, let's cultivate a future filled with love, wisdom,
                <br className="hidden md:block" />
                and devotion through the teachings of Lord Krishna
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default YouthSeminar;
