import React from "react";
import top from "../img/floral-lotus-mandala-clipart-black-vector-art-600x600-removebg-preview 1.png";
import bottom from "../img/floral-lotus-mandala-clipart-black-vector-art-600x600-removebg-preview 2.png";
import "./page2.css";

const Page2 = () => {
  return (
    <div className="features-section">
      <div className="background-image">
        <img
          className="top-left-image"
          src={top}
          alt="Top Left Design"
          loading="lazy"
        />
        <img
          className="bottom-right-image"
          src={bottom}
          alt="Bottom Right Design"
          loading="lazy"
        />
      </div>
      <div className="features-container">
        <div className="features-content">
          <h1>Key Features Of The Bhakti Shastri Program:</h1>
          <ul>
            <li>
              <strong>Comprehensive Study:</strong> Focus On Four Essential
              Vaishnava Texts—Bhagavad-Gita, Sri Isopanisad, Nectar Of Devotion,
              And Nectar Of Instruction.
            </li>
            <li>
              <strong>Guided Learning:</strong> Experienced Instructors Guide
              Participants Through The Texts, Encouraging Critical Thinking And
              Application.
            </li>
            <li>
              <strong>Interactive Format:</strong> Includes Discussions,
              Quizzes, And Group Activities To Ensure A Practical Understanding
              Of The Scriptures.
            </li>
            <li>
              <strong>Certificate Program:</strong> Upon Successful Completion,
              Participants Receive The Bhakti Shastri Certificate, Recognized
              Across All ISKCON Centers Worldwide.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Page2;
