import React, { useEffect } from "react";
import "./styles.css";
import bhakti1 from "./assets/bhakti1.jpg";
import bhakti2 from "./assets/bhakti2.jpg";
import mobPage from "./assets/mob-page.jpg";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Page1 from "./page1/page1";
import Page3 from "./page3/page3";

function BhaktiVriksha() {
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <Page1 />
      <Page3 />
      {/* <div className="desktop w-full bg-[#ffecdb] md:mt-8">
        <img src={bhakti1} alt="" />
        <img src={bhakti2} alt="" />
      </div> */}
      {/* <div className='mobile w-full'>
        <img src={mobPage} alt="" />
      </div> */}
      <Footer />
    </div>
  );
}

export default BhaktiVriksha;
