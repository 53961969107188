import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import MapComponent from './MapComponent'

function HowToReach() {
  return (
    <div>
        <Header/>
        <MapComponent/>
        <Footer/>
      
    </div>
  )
}

export default HowToReach
